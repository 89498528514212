import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import styles from "./login.module.scss";
import { history } from "_helpers";
import { login } from "_store";
import { useLoginMutation } from "_store/auth/authApi.slice";
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, ListItemIcon, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

import Flag from 'react-world-flags'
import { useTranslation } from "react-i18next";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';



// text field
const RedditTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor:
      theme.palette.mode === "transparent" ? "transparent" : "transparent",
    transition:
      "border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    // borderRadius: "8px",
    // transition: theme.transitions.create([
    //   "border-color",
    //   "background-color",
    //   "box-shadow",
    // ]),
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      boxShadow: "0px 0px 0px 2px rgb(67, 56, 202)",
      borderColor: "rgb(67, 56, 202)",
    },
  },

}));

const Login = () => {
  const dispatch = useDispatch();
  const authUser = useSelector((x) => x.auth);
  const { isLogin } = authUser.user || {};
  // const authError = useSelector((x) => x.auth.error);

  const [loginAPI, { isError, error }] = useLoginMutation();
  const data = JSON.parse(error?.data ?? null) || {};
  useEffect(() => {
    // redirect to home if already logged in
    if (isLogin) {
      history.navigate("/transactions");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().required("Email is required"),
    password: Yup.string().trim().required("Password is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  async function onSubmit({ email, password }) {
    // const encryptedPass = bcrypt.hashSync(password, salt)
    const userData = await loginAPI({ email, password }).unwrap();
    dispatch(login({ ...JSON.parse(userData) }));
  }
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
  }

  const [showPassword, setShowPassword] = useState(false);


  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    // <div className={`${styles.login_wrap} col-md-6 offset-md-3 mt-5`}>
    //   <div className="alert alert-info">
    //     Username: test
    //     <br />
    //     Password: test
    //   </div>
    //   <div className="card">
    //     <h4 className="card-header">Login</h4>
    //     <div className="card-body">
    //       <form onSubmit={handleSubmit(onSubmit)}>
    //         <div className="form-group">
    //           <label>Username</label>
    //           <input
    //             name="username"
    //             type="text"
    //             {...register("username")}
    //             className={`form-control ${
    //               errors.username ? "is-invalid" : ""
    //             }`}
    //           />
    //           <div className="invalid-feedback">{errors.username?.message}</div>
    //         </div>
    //         <div className="form-group">
    //           <label>Password</label>
    //           <input
    //             name="password"
    //             type="password"
    //             autoComplete="false"
    //             {...register("password")}
    //             className={`form-control ${
    //               errors.password ? "is-invalid" : ""
    //             }`}
    //           />
    //           <div className="invalid-feedback">{errors.password?.message}</div>
    //         </div>
    //         <button disabled={isLoading} className="btn btn-primary">
    //           {isLoading && (
    //             <span className="spinner-border spinner-border-sm mr-1"></span>
    //           )}
    //           Login
    //         </button>
    //         {isError && (
    //           <div className="alert alert-danger mt-3 mb-0">{data.detail}</div>
    //         )}
    //       </form>
    //     </div>
    //   </div>
    // </div>
    <>
      <Grid container>
        <Grid item xs={12}>
          <FormControl className={`${styles.select_language} form_box`}>
            <InputLabel id="demo-simple-select-label">Language</InputLabel>
            <Select sx={{ maxWidth: "200px" }}
              value={i18n.language}
              label="Language"
              onChange={(event) => handleLanguageChange(event.target.value)}
            >
              <MenuItem value={"en"}>
                <ListItemIcon>
                  <Flag code={'gb'} />
                </ListItemIcon>
              </MenuItem>
              <MenuItem value={"de"}>
                <ListItemIcon>
                  <Flag code={'de'} />
                </ListItemIcon>
              </MenuItem>
              {/* <MenuItem value={"de"}>German</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={8}>
          <Box className={styles.auth_box}>
            {/* <Box className={styles.auth_back}>
            <Typography>
              <ArrowBackIcon /> Dashboard
            </Typography>
          </Box> */}
            <Box className={styles.auth_data_box}>
              <Box className={styles.auth_title_top}>
                <Typography className={styles.auth_title} variant="h5">
                  {t('login')}
                </Typography>
              </Box>
              <Box className={styles.auth_data_body}>
                <form
                  className={styles.auth_form}
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {/* <form onSubmit={onSubmit}> */}
                  <RedditTextField
                    fullWidth
                    name="email"
                    label={t('EmailAddress')}
                    defaultValue=""
                    variant="filled"
                    inputProps={{ ...register("email") }}
                  />
                  {errors?.email?.message && (
                    <FormHelperText>
                      {errors?.email?.message}
                    </FormHelperText>
                  )}
                  <RedditTextField
                    fullWidth
                    name="password"
                    label={t('password')}
                    type={showPassword ? 'text' : 'password'}
                    defaultValue=""
                    variant="filled"
                    inputProps={{ ...register("password") }}
                    style={{ marginTop: "24px" }}
                    InputProps={{
                      endAdornment: showPassword ? (
                        <RemoveRedEyeIcon
                          fontSize="small"
                          onClick={handlePasswordVisibility}
                        />
                      ) : (
                        <VisibilityOffIcon
                          fontSize="small"
                          onClick={handlePasswordVisibility}
                        />
                      ),
                    }} />
                  {errors?.password?.message && (
                    <FormHelperText>
                      {errors?.password?.message}
                    </FormHelperText>
                  )}
                  <Button type="submit" className={styles.auth_button}>
                    {t('Login')}
                  </Button>
                  {isError && (
                    <Box sx={{ color: "red" }}>
                      {data?.detail}
                    </Box>
                  )}
                </form>
                <Box className={styles.auth_bottom_text}>
                  <Link to='/forgot-password'>{t('forgetpassword')}?</Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>

  );
}

export { Login };