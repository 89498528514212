import { createSlice } from "@reduxjs/toolkit";

// create slice
const name = "auth";
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

// exports
export const connectionActions = { ...slice.actions };
export const connectionReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        connections: [],
        error: null,
    };
}

function createReducers() {

    const setConnections = (state, action) => {
        state.connections = action.payload || []
    };

    return {
        setConnections
    };
}

export const { setConnections } = slice.actions;

