/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Menu,
  Skeleton,
  Badge,
} from "@mui/material";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import React, { useEffect, useMemo, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./transaction.module.scss";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PropTypes from "prop-types";
import AssignmentTurnedInTwoToneIcon from "@mui/icons-material/AssignmentTurnedInTwoTone";
import DocumentScannerTwoToneIcon from "@mui/icons-material/DocumentScannerTwoTone";
import { useTranslation } from "react-i18next";
import {
  useLazyPaypalQuery,
  useLazyPaypal_TransactionsQuery,
  useLazyTransactionsQuery,
} from "_store/transaction/transactionApi.slice";
import { useLazyUpdateBankDataQuery, useLazyCheckUpdateBankDataQuery, useHideBankDataMutation } from "_store/connection/connectionApi.slice";
import DescriptionIcon from "@mui/icons-material/Description";
import ConfirmModal from "_components/modals/ConfirmModal";
import ReusableSnackbar from "../../_components/snackbar";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  useLazyGetInvoicesPDFQuery,
  useLazyGetInvoiceQuery,
  useInvoicesUpdateMutation,
} from "_store/invoice/invoiceApi.slice";
import {
  useCreditNotesUpdateMutation,
  useLazyGetCreditNotesPDFQuery,
  useLazyGetCreditNotesQuery,
} from "_store/credit_notes/creditNotesApi.slice";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { FaPaypal } from "react-icons/fa";
function useOutsideAlerter(ref, setSate) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setSate(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Transaction = () => {
  //States

  const [value, setValue] = React.useState(0);

  const [currentCardEntries, setCurrentCardEntries] = React.useState([]);
  const [confirmModal, setConfirmModal] = React.useState(false);
  //Listing
  const [hideList, setHideList] = useState([]);
  const [List, setList] = useState([]);
  const [creditNotesList, setCreditNotesList] = useState([]);
  const [invoicesList, setInvoicesList] = useState([]);
  const [showList, setShowList] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showOpenAmount, setShowOpenAmount] = useState(0);
  const [showUnpaidAmount, setShowUnpaidAmount] = useState(0);
  const [tempList, setTempList] = useState([]);
  //PDF
  const [getInvoicePDFAPICall] = useLazyGetInvoicesPDFQuery();
  const [getCreditNotesPDFAPICall] = useLazyGetCreditNotesPDFQuery();

  //get trnsaction data
  const [
    getTransactions,
    {
      data,

      isError: isTransactionsError,
      isFetching: isTransactionsLoading,
    },
  ] = useLazyTransactionsQuery();

  async function getUserIPAddress() {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Fehler beim Abrufen der IP-Adresse:', error);
      return null;
    }
  }

  const [getNewBankData, { data: bankData, error: isBankdataError, isLoading: isBankdataLoading }] = useLazyUpdateBankDataQuery();
  const [getNewBankUpdateData, { data: bankUpdateData, error: isBankUpdatedataError, isLoading: isBankUpdatedataLoading }] = useLazyCheckUpdateBankDataQuery();
  const [updateHideBankData, { data: hideBankData, error: isHideBankdataError, isLoading: isHideBankdataLoading }] = useHideBankDataMutation();

  //update Bankdata
  const updateBankdata = async () => {
    try {
      setPopUpShow((prev) => ({ ...prev, bankData: true }));
      const userIPAddress = await getUserIPAddress();
      const response = await getNewBankData(userIPAddress);
      const { data: bankData, error: isBankdataError, isLoading: isBankdataLoading } = response;

      if (isBankdataError) {
        console.error('Fehler beim Abrufen der Daten:', isBankdataError);
        return;
      }
      if (bankData.url) {
        let webformUrl = bankData.url;
        let webformUpdateId = bankData.webFormId
        window.open(webformUrl, '_blank');

        let bankUpdateStatus = '';
        while (bankUpdateStatus !== 'COMPLETED') {
          setPopUpShow((prev) => ({ ...prev, getUpdateBank: true }));
          const response = await getNewBankUpdateData(webformUpdateId);
          const { data: bankUpdateData, error: isBankUpdatedataError, isLoading: isBankUpdatedataLoading } = response;

          if (isBankUpdatedataError) {
            console.error('Fehler beim Abrufen der Daten:', isBankUpdatedataError);
            return;
          }

          bankUpdateStatus = bankUpdateData.status;

          if (bankUpdateStatus === 'COMPLETED') {
            console.log('Bankdaten wurden aktualisiert:', bankUpdateData);
            setPopUpShow((prev) => ({ ...prev, getUpdateBank: false }));
            const getAllTransactions = async () => {
              try {
                await getTransactions()
                  .unwrap()
                  .then((res) => { });
                setPopUpShow((prev) => ({ ...prev, bankData: false }));
                ShowList();
              } catch (err) {
                setPopUpShow((prev) => ({ ...prev, bankData: false }));
              }

            };
            getAllTransactions();
          } else if (bankUpdateStatus === 'ABORTED') {
            alert('Fehler beim Abruf der Bankdaten, bitte erneut versuchen oder Support kontaktieren.')
            setPopUpShow((prev) => ({ ...prev, bankData: false }));
          } else {
            console.log('Bankdaten sind noch nicht abgeschlossen. Warte 2 Sekunden und wiederhole den Abruf.');
            await new Promise(resolve => setTimeout(resolve, 2000));
          }
        }
      } else {
        console.error('Keine URL in den empfangenen Daten gefunden.');
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Daten:', error);
    }
  };

  //get paypal_trsanctions
  const [getPaypalTransactions, { data: paypal_Transactions }] =
    useLazyPaypal_TransactionsQuery();
  //get extra paypal_transactions
  const [getPaypal, { isSuccess: paypal_success }] = useLazyPaypalQuery();
  //get invoice data
  const [
    getInvoice,
    {
      data: invoicesData,
      isFetching: isGetInvoiceDataLoading,
      isError: isGetInvoiceDataError,
    },
  ] = useLazyGetInvoiceQuery();

  //get creditnotes data
  const [
    getCreditNotes,
    {
      data: creditNotesData,
      isFetching: isGetCreditNotesDataLoading,
      isError: isGetCreditNotesDataError,
    },
  ] = useLazyGetCreditNotesQuery();
  const [invoicesUpdate] = useInvoicesUpdateMutation();
  const [creditNotesUpdate] = useCreditNotesUpdateMutation();
  const filterRef = useRef(null);
  const { t } = useTranslation();
  const [checkedValues, setCheckedValues] = React.useState([]);
  const [rightCheckedValues, setRightCheckedValues] = React.useState([]);
  const [tempFilteredTransArr, setTempFilteredTransArr] = React.useState({});
  const [isShowSelectedInvoice, setIsShowSelectedInvoice] =
    React.useState(false);
  const [filteredInvoiceArr, setFilteredInvoiceArr] = React.useState([]);
  const [tempFilteredInvoiceArr, setTempFilteredInvoiceArr] = React.useState(
    []
  );
  const [selectedInvoiceCheckedValue, setSelectedInvoiceCheckedValue] =
    React.useState([]);
  const [tempRemoveFilteredInvoiceArr, setTempRemoveFilteredInvoiceArr] =
    React.useState([]);

  const [customSnackbar, setCustomSnackbar] = React.useState({
    open: false,
    toast_message: "",
    severity: "success",
  });

  const [currencyLists, setcurrencyLists] = React.useState([]);

  const [isTransFilterOpen, setIsTransFilterOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [filters, setFilters] = React.useState({
    serviceType: "",
    currencyType: "",
    startDate: null,
    endDate: null,
    searchQuery: "",
  });

  const [startDate, setStartDate] = React.useState("");

  const [endDate, setEndDate] = React.useState("");
  const [itemID, setItemId] = React.useState("");
  const [confirmHide, setConfirmHide] = React.useState(false);
  //state for pdf
  const [isEmbed, setIsEmbed] = useState(false);
  const [pdfURL, setPdfURL] = useState("");

  //state for textview
  const [textView, setTextView] = React.useState({
    itemId: "",
    more: false,
  });

  // state for pupop
  const [popUpShow, setPopUpShow] = useState({
    creditNotes: true,
    invoices: true,
    transactions: true,
    updateBank: false,
    getUpdateBank: false,
  });


  useOutsideAlerter(filterRef, setIsTransFilterOpen);
  //Effects for set Lists

  useMemo(() => {
    setCreditNotesList(
      creditNotesData &&
      !isGetCreditNotesDataError &&
      creditNotesData?.results?.filter((item) => item.status !== "Payed")
    );
  }, [creditNotesData]);

  useMemo(() => {

    setInvoicesList(
      invoicesData?.results.filter((item) => item.status !== "Payed") || []
    );
  }, [invoicesData]);


  const getOpenList = () => {
    if (paypal_Transactions && paypal_Transactions.results.length >= 1) {
      let combinedArray;
      let temp1 =
        !isTransactionsError &&
        data &&
        data?.filter((item) => item.status !== "Pending");
      temp1 && temp1.length >= 1
        ? (combinedArray = [...temp1, ...paypal_Transactions.results])
        : (combinedArray = [...paypal_Transactions.results]);
      return combinedArray;
    } else {
      return (
        !isTransactionsError &&
        !isTransactionsLoading &&
        data &&
        data?.filter((item) => item.status === "Pending")
      );
    }
  };

  const getHideList = () => {
    return (
      !isTransactionsError &&
      !isTransactionsLoading &&
      data &&
      data?.filter((item) => item.status === "Hide")
    );
  };
  


  useEffect(() => {
    let openList = getOpenList();
    let listHiden = getHideList();
    setHideList(listHiden);
    setList(openList);
    setTempList(openList);
    let arrayCurrency = [];
    data?.forEach((item, i) => {

      if (arrayCurrency.indexOf(item.currency) === -1)
        arrayCurrency.push(item.currency);
    });

    paypal_Transactions?.results?.forEach((item, i) => {
      if (item.currency !== "" && arrayCurrency.indexOf(item.currency) === -1)
        arrayCurrency.push(item.currency);
      else if (item.currency === "" && !arrayCurrency?.includes("EUR"))
        arrayCurrency.push("EUR");
    });
    setcurrencyLists(arrayCurrency);
  }, [data, paypal_Transactions, paypal_Transactions?.results?.length]);

  useEffect(() => {
    const getAllInvoice = async () => {
      try {
        await getInvoice().unwrap();
        setPopUpShow((prev) => ({ ...prev, invoices: false }));
      } catch (err) {
        console.log(err);
        setPopUpShow((prev) => ({ ...prev, invoices: false }));
      }
    };
    const getAllCreditNotes = async () => {
      try {
        await getCreditNotes().unwrap();
        setPopUpShow((prev) => ({ ...prev, creditNotes: false }));
      } catch (err) {
        console.log(err);
        setPopUpShow((prev) => ({ ...prev, creditNotes: false }));
      }
    };
    const getAllTransactions = async () => {
      try {
        await getTransactions()
          .unwrap()
          .then((res) => { });
        setPopUpShow((prev) => ({ ...prev, transactions: false }));
      } catch (err) {
        setPopUpShow((prev) => ({ ...prev, transactions: false }));
      }

    };
    getAllInvoice();
    getAllCreditNotes();
    getAllTransactions();
    getPaypal();
  }, []);
  useEffect(() => {
    if (paypal_success) {
      getPaypalTransactions();
    }
  }, [paypal_success, getPaypalTransactions]);

  //Functions
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ResetPage = () => {
    setTempFilteredTransArr({});
    setRightCheckedValues([]);
    setShowOpenAmount(0);
    setShowUnpaidAmount(0);
    setFilteredInvoiceArr([]);
    setCurrentCardEntries([]);
    setCheckedValues([]);
    setTempFilteredInvoiceArr([]);
  };

  const handleClickEntries = (key, event) => {
    event.preventDefault();
    const selectedTransArr = [...invoicesList, ...(creditNotesList ? [creditNotesList] : [])].filter(
      (item) => item.id === key
    )[0];

    const Tcurrency = tempFilteredTransArr.currency
      ? tempFilteredTransArr.currency
      : "EUR";
    if (Tcurrency !== selectedTransArr.currency) {
      setCustomSnackbar({
        open: true,
        severity: "warning",
        toast_message: "Currency not match.",
      });
    } else {
      if (isIncludeEntry(key)) {
        let data = currentCardEntries.filter((item) => item !== key);
        setCurrentCardEntries(data);
      } else {
        setCurrentCardEntries((old) => [...old, key]);
      }

      if (checkedValues.includes(key)) {
        setCheckedValues((prev) => prev.filter((id) => id !== key));
        setTempFilteredInvoiceArr((pre) =>
          pre.filter((item) => item.id !== key)
        );
        return;
      }

      handleCheckboxChange(key);

      let temp = false;
      if (filteredInvoiceArr && filteredInvoiceArr.length >= 1) {
        filteredInvoiceArr &&
          filteredInvoiceArr.forEach((item) => {
            temp = item.invoice_number !== undefined;
          });
      }

      //check condition for unpaid amount are greater than open amount
      if (showUnpaidAmount > showOpenAmount && temp) {
        if (selectedTransArr.credit_note_number !== undefined) {
          setTempFilteredInvoiceArr((prev) => [...prev, selectedTransArr]);
        }
      } else {
        if (selectedTransArr.credit_note_number !== undefined && temp) {
          setTempFilteredInvoiceArr((prev) => [...prev, selectedTransArr]);
        } else if (selectedTransArr.invoice_number !== undefined) {
          setTempFilteredInvoiceArr((prev) => [...prev, selectedTransArr]);
        }
      }
    }
  };

  const isIncludeEntry = (key) => {
    return currentCardEntries.includes(key);
  };

  //Modal handling
  const handleShowConfirmModal = () => {
    setConfirmModal(true);
  };

  //Reload data
  const reloadTransaction = () => {
    getCreditNotes();
    getTransactions();
    getInvoice();
  };

  const handleSuccessConfirmModal = async () => {
    if (confirmHide === true) {
      if (!showList) {
        let Titem = List.find((item) =>
          item.bank_id ? item.bank_id === itemID : item.id === itemID
        );
        setList(
          List.filter((item) =>
            item.bank_id ? item.bank_id !== itemID : item.id !== itemID
          )
        );
        setHideList((prevHideList) => [...prevHideList, Titem]);

        // Update Database
        let bankId = Titem.id;
        let hideBankStatus = '';
        let hideState = 1;
        const response = await updateHideBankData({ bankId, hideState });
        const { data: hideBankData, error: isHideBankdataError, isLoading: isHideBankdataLoading } = response;
        if (isHideBankdataError) {
          console.error('Fehler beim Abrufen der Daten:', isHideBankdataError);
          return;
        }
        hideBankStatus = hideBankData.status;

      } else {
        let Titem = data.find((item) => item.id === itemID);
        if (Titem === undefined) {
          Titem = paypal_Transactions?.results.find(
            (item) => item.bank_id === itemID
          );
        }
        setList((prevList) => [...prevList, Titem]);
        setHideList(
          hideList.filter((item) =>
            item.bank_id ? item.bank_id !== itemID : item.id !== itemID
          )
        );
        // Update Database
        let bankId = Titem.id;
        let hideBankStatus = '';
        let hideState = 0;
        const response = await updateHideBankData({ bankId, hideState });
        const { data: hideBankData, error: isHideBankdataError, isLoading: isHideBankdataLoading } = response;
        if (isHideBankdataError) {
          console.error('Fehler beim Abrufen der Daten:', isHideBankdataError);
          return;
        }
        hideBankStatus = hideBankData.status;
      }
    } else {
      let TotalInvoices = filteredInvoiceArr && filteredInvoiceArr.length;

      filteredInvoiceArr?.forEach((item, index) => {
        let payload = {
          transaction_id:
            tempFilteredTransArr && tempFilteredTransArr?.bank_id
              ? tempFilteredTransArr?.bank_id
              : tempFilteredTransArr?.transaction_id,
          account_transaction_id: null,
          account_id: null,
          type: "N",
        };
        if (item.invoice_number) {
          // TotalCount = TotalCount + 1
          if (TotalInvoices === index + 1) {
            payload = {
              ...payload,
              invoice_id: item.sevdesk_id,
              amount:
                showUnpaidAmount > 0
                  ? tempFilteredTransArr && tempFilteredTransArr?.open_amount
                    ? parseFloat(tempFilteredTransArr?.open_amount?.toFixed(2))
                    : parseFloat(
                      tempFilteredTransArr?.amount_in_euros?.toFixed(2)
                    )
                  : showOpenAmount > 0
                    ? parseFloat(item.amount_in_euros?.toFixed(2))
                    : parseFloat(item.amount_in_euros?.toFixed(2)),
              open_amount: parseFloat(showOpenAmount?.toFixed(2)),
              unpaid_amount: parseFloat(showUnpaidAmount?.toFixed(2)),
            };
          } else {
            payload = {
              ...payload,
              invoice_id: item.sevdesk_id,
              amount:
                showUnpaidAmount > 0
                  ? tempFilteredTransArr && tempFilteredTransArr?.open_amount
                    ? parseFloat(tempFilteredTransArr?.open_amount?.toFixed(2))
                    : parseFloat(
                      tempFilteredTransArr?.amount_in_euros?.toFixed(2)
                    )
                  : showOpenAmount > 0
                    ? parseFloat(item.amount_in_euros?.toFixed(2))
                    : parseFloat(item.amount_in_euros?.toFixed(2)),
              open_amount: parseFloat(showOpenAmount?.toFixed(2)),
            };
          }
          invoicesUpdate(payload)
            .unwrap()
            .then(() => {
              reloadTransaction();
            })
            .catch((error) => { });
        } else if (item.credit_note_number) {
          payload = {
            ...payload,
            credit_note_id: item.sevdesk_id,
            amount: item.amount_in_euros?.toFixed(2),
            open_amount: showOpenAmount?.toFixed(2),
            unpaid_amount: showUnpaidAmount?.toFixed(2),
          };
          creditNotesUpdate(payload)
            .unwrap()
            .then(() => {
              reloadTransaction();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }
    setConfirmHide(false);
    setConfirmModal(false);
    ResetPage();
  };

  const handleCheckboxChange = (data) => {
    const value = data;
    const index = checkedValues.indexOf(value);
    if (index > -1) {
      setCheckedValues(checkedValues.filter((v) => v !== value));
    } else {
      setCheckedValues([...checkedValues, value]);
    }
  };

  const handleToastClose = () => {
    setCustomSnackbar({ ...customSnackbar, open: false });
  };

  const handleclose = () => {
    if (confirmHide) {
      setConfirmHide(false);
    }
    setConfirmModal(false);
    setCheckedValues([]);
  };

  const handleMenu = (event, id) => {
    setItemId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //Others
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Box>{children}</Box>
          </Box>
        )}
      </Box>
    );
  }

  //Filteration for transaction
  useEffect(() => {
    let tmpFilter = { ...filters };
    //Service filteration
    let filteredData = tempList && tempList.length > 0 && tempList?.filter((item) => {
      if (tmpFilter.serviceType === "") return true;
      else if (tmpFilter.serviceType === "PAYPAL")
        return item?.bank_name?.toUpperCase() === tmpFilter.serviceType;
      else if (tmpFilter.serviceType === "BANK")
        return item?.bank_name?.toUpperCase() !== "PAYPAL";
      else if (tmpFilter.serviceType === "ALL") return true;
      else return true;
    });
    filteredData = filteredData && filteredData.length > 0 && filteredData?.filter((item) => {
      if (tmpFilter.currencyType === "") return true;
      else if (tmpFilter.currencyType === item?.currency?.toUpperCase())
        return true;
      else if (tmpFilter.currencyType === "ALL")
        return true;
      else if (tmpFilter.currencyType === "EUR")
        return item?.currency === "";
      else return false;
    });

    //Date filteration
    filteredData = filteredData && filteredData.length > 0 && filteredData?.filter((item) => {
      if (
        item?.bank_booking_date === "" ||
        tmpFilter.startDate === null ||
        tmpFilter.endDate === null ||
        item?.valid_date === ""
      )
        return true;
      else {
        let booking_date = new Date(
          item?.valid_date ? item?.valid_date : item?.bank_booking_date
        );
        let start = new Date(tmpFilter.startDate);
        let end = new Date(tmpFilter.endDate);
        return booking_date > start && booking_date < end;
      }
    });

    //Search query
    filteredData = filteredData && filteredData.length > 0 && filteredData?.filter((item) => {
      if (
        item?.client_name === "" ||
        tmpFilter.searchQuery === "" ||
        item?.user_details === ""
      )
        return true;
      else {
        let client = item?.bank_name
          ? item?.user_details?.toLowerCase()
          : item?.client_name?.toLowerCase();
        return client?.includes(tmpFilter.searchQuery?.toLowerCase());
      }
    });
    setList(filteredData);
  }, [
    filters,
    data,
    paypal_Transactions,
    paypal_Transactions?.results?.length,
    tempList,
  ]);



  //select transaction for filtering
  const addSelectedTrans = async (key) => {
    setShowOpenAmount(0);
    setShowUnpaidAmount(0);
    setFilteredInvoiceArr([]);
    setCheckedValues([]);
    if (rightCheckedValues && rightCheckedValues?.length === 0) {
      setRightCheckedValues([key]);
    } else if (
      rightCheckedValues &&
      rightCheckedValues?.length &&
      rightCheckedValues?.length > 0
    ) {
      rightCheckedValues.shift();
      setRightCheckedValues([key]);
    }
    const selectedTransArr = List?.find((item) =>
      item.bank_id ? item.bank_id === key : item.id === key
    );

    setTempFilteredTransArr((pre) => selectedTransArr);
    selectedTransArr && selectedTransArr?.open_amount
      ? setShowOpenAmount(selectedTransArr?.open_amount)
      : selectedTransArr?.bank_id && selectedTransArr?.open_amount
        ? setShowOpenAmount(selectedTransArr?.open_amount)
        : selectedTransArr?.bank_id &&
          selectedTransArr?.amount_in_foreign_currency
          ? setShowOpenAmount(selectedTransArr?.amount_in_foreign_currency)
          : selectedTransArr?.bank_id && selectedTransArr?.amount_in_euros
            ? setShowOpenAmount(selectedTransArr?.amount_in_euros)
            : selectedTransArr?.amount_in_foreign_currency
              ? setShowOpenAmount(
                selectedTransArr && selectedTransArr?.amount_in_foreign_currency
              )
              : setShowOpenAmount(
                selectedTransArr && selectedTransArr?.amount_in_euros
              );
    try {
      await getInvoice(
        selectedTransArr && selectedTransArr?.bank_id
          ? selectedTransArr?.bank_id
          : selectedTransArr?.transaction_id
      )
    } catch (err) {
      console.log(err);
    }

    try {
      await getCreditNotes(
        selectedTransArr && selectedTransArr?.bank_id
          ? selectedTransArr?.bank_id
          : selectedTransArr?.transaction_id
      )
    } catch (err) {
      console.log(err);
    }
  };

  //Change handlers
  const handleChangeServiseType = (event) => {
    setFilters((old) => ({ ...old, serviceType: event?.target?.value ?? "" }));
  };
  const handleChangeCurrencyType = (event) => {
    setFilters((old) => ({ ...old, currencyType: event?.target?.value ?? "" }));
  };

  const handleChangeSearchQuery = (event) => {
    setFilters((old) => ({ ...old, searchQuery: event.target.value }));
  };

  const handleChangeStartDate = (startDate) => {
    setStartDate(startDate);
    setFilters((old) => ({
      ...old,
      startDate: startDate.format("YYYY-MM-DD"),
    }));
  };

  const handleChangeEndDate = (endDate) => {
    setEndDate(endDate);
    setFilters((old) => ({ ...old, endDate: endDate.format("YYYY-MM-DD") }));
  };

  const handleSelectedInvoiceCheckboxChange = (data) => {
    // const value = event.target.value;
    const value = data;

    const index = selectedInvoiceCheckedValue.indexOf(value);
    if (index > -1) {
      setSelectedInvoiceCheckedValue(
        selectedInvoiceCheckedValue.filter((v) => v !== value)
      );
    } else {
      // setOpen(true);
      // setMessage(t("YouSelected") + " " + value + ".");
      setSelectedInvoiceCheckedValue([...selectedInvoiceCheckedValue, value]);
    }
  };

  const handleSelectedInvoice = (key) => {
    if (selectedInvoiceCheckedValue.includes(key)) {
      setSelectedInvoiceCheckedValue((prev) => prev.filter((id) => id !== key));
      setTempRemoveFilteredInvoiceArr((pre) =>
        pre.filter((item) => item.id !== key)
      );
      return;
    }

    handleSelectedInvoiceCheckboxChange(key);
    const selectedTransArr = filteredInvoiceArr.filter(
      (item) => item.id === key
    )[0];
    // Add the new object to the array using the spread operator
    const newArray = [...tempRemoveFilteredInvoiceArr, selectedTransArr];
    // Update the state with the new array
    setTempRemoveFilteredInvoiceArr(newArray);
  };

  //invoice download
  const InvoiceDownload = async (id) => {
    await getInvoicePDFAPICall(id)
      .unwrap()
      .then((res) => {
        if (res?.objects?.content) {
          setIsEmbed(true);
          setPdfURL(`data:application/pdf;base64,${res?.objects?.content}`);
        }
      })
      .catch((err) => console.log(err));
  };
  //credit_notes download
  const CreditNoteDownload = async (id) => {
    await getCreditNotesPDFAPICall(id)
      .unwrap()
      .then((res) => {
        if (res?.objects?.content) {
          setIsEmbed(true);
          setPdfURL(`data:application/pdf;base64,${res?.objects?.content}`);
        }
      })
      .catch((err) => console.log(err));
  };

  const handlePDFClose = () => {
    setIsEmbed(false);
    setPdfURL("");
  };

  //calculation for open/unpaid amount
  const handleAddInvoice = () => {
    setIsShowSelectedInvoice(true);
    if (tempFilteredInvoiceArr?.length > 0) {
      const filteredInvoiceFn = () => {
        let result = filteredInvoiceArr.slice();

        tempFilteredInvoiceArr.forEach((obj) => {
          if (!result.some((item) => item.id === obj.id)) {
            result.push(obj);
          }
        });
        return result;
      };
      const filteredInvoice = filteredInvoiceFn();
      setFilteredInvoiceArr(filteredInvoice);
      setTempFilteredInvoiceArr([]);
      //add calculation part
      let TopenAmt = showOpenAmount;
      let TunpaidAmt = 0;

      tempFilteredInvoiceArr.forEach((item) => {
        if (item.invoice_number !== undefined) {
          TopenAmt = TopenAmt - item.unpaid_amount;
        }
        if (item.credit_note_number !== undefined) {
          TopenAmt = item.amount_in_foreign_currency
            ? TopenAmt + item.amount_in_foreign_currency
            : TopenAmt + item.amount_in_euros;
        }
      });

      if (TopenAmt < 0) {
        TunpaidAmt = TunpaidAmt + Math.abs(TopenAmt);
        setShowUnpaidAmount(TunpaidAmt);
        setShowOpenAmount(0);
      } else if (TopenAmt === 0 && TunpaidAmt === 0) {
        setShowOpenAmount(0);
        setShowUnpaidAmount(0);
      } else {
        setShowOpenAmount(TopenAmt);
        setShowUnpaidAmount(0);
      }
    }
  };

  const removeSelectedInvoice = () => {
    if (tempRemoveFilteredInvoiceArr?.length) {
      const filteredArray1 = filteredInvoiceArr.filter(
        (obj1) =>
          !tempRemoveFilteredInvoiceArr.find((obj2) => obj1.id === obj2.id)
      );
      const selectedIds = filteredArray1.map((obj) => obj.id);
      setCheckedValues(selectedIds);
      setSelectedInvoiceCheckedValue([]);
      setFilteredInvoiceArr(filteredArray1);
      setTempRemoveFilteredInvoiceArr([]);

      let TopenAmt =
        tempFilteredTransArr && tempFilteredTransArr?.open_amount
          ? tempFilteredTransArr?.open_amount
          : tempFilteredTransArr?.amount_in_foreign_currency
            ? tempFilteredTransArr?.amount_in_foreign_currency
            : tempFilteredTransArr?.amount_in_euros;
      let TunpaidAmt = 0;
      filteredArray1.forEach((item) => {
        if (item.invoice_number !== undefined) {
          TopenAmt = TopenAmt - item.unpaid_amount;
        }
        if (item.credit_note_number !== undefined) {
          TopenAmt = item.amount_in_foreign_currency
            ? TopenAmt + item.amount_in_foreign_currency
            : TopenAmt + item.amount_in_euros;
        }
      });

      if (TopenAmt < 0) {
        TunpaidAmt = TunpaidAmt + Math.abs(TopenAmt);
        setShowUnpaidAmount(TunpaidAmt);
        setShowOpenAmount(0);
      } else if (TopenAmt === 0 && TunpaidAmt === 0) {
        setShowOpenAmount(0);
        setShowUnpaidAmount(0);
      } else {
        setShowOpenAmount(TopenAmt);
        setShowUnpaidAmount(0);
      }
    }
  };
  const CustomSkeleton = () =>
    Array.from({ length: 3 }).map((_, key) => (
      <Grid item xs={12} md={12} key={key}>
        <Box>
          <Skeleton variant="rounded" height={140} width={345} />
        </Box>
      </Grid>
    ));

  const handleHide = () => {
    setConfirmHide(true);
    handleShowConfirmModal();
    setAnchorEl(null);
  };
  const ShowHideList = () => {
    setShowList(true);
    setShowCompleted(false);
  };
  const ShowList = () => {
    setShowCompleted(false);
    let Tempdata;
    if (hideList && hideList.length > 0) {
      Tempdata = getOpenList();
      let tempArr = Tempdata.filter((item) => item.status !== "Pending");
      const hasCommonElement = tempArr.filter(
        (item) =>
          !hideList.some((hiddenItem) =>
            hiddenItem.bank_id
              ? hiddenItem.bank_id === item.bank_id
              : hiddenItem.id === item.id
          )
      );
      setList(hasCommonElement);
    } else {
      Tempdata = getOpenList();
      setList(Tempdata);
    }
    setShowList(false);
  };
  const ShowCompletedList = () => {
    setShowCompleted(true);
    setShowList(false);
    if (hideList && hideList.length > 0) {
      let tempArr = data.filter((item) => item.status === "Completed");
      const hasCommonElement = tempArr.filter(
        (item) =>
          !hideList.some((hiddenItem) =>
            hiddenItem.bank_id
              ? hiddenItem.bank_id === item.bank_id
              : hiddenItem.id === item.id
          )
      );

      setList(hasCommonElement);
    } else {
      setList(data?.filter((item) => item.status === "Completed"));
    }
  };

  const ShowAllTransactions = () => {
    ShowCompletedList();
    ShowList();
  }
  const handleMore = (item) => {
    setTextView({
      itemId: item,
      more: true,
    });
  };
  const handleLess = (item) => {
    setTextView({
      itemId: item,
      more: false,
    });
  };
  //clear filteration
  const clearAllFilteres = () => {
    setFilters({
      serviceType: "",
      startDate: null,
      endDate: null,
      searchQuery: "",
      currencyType: "",
    });
    setEndDate("");
    setStartDate("");
    setList(data && data?.filter((item) => item.status=== "Pending"));
    setInvoicesList(invoicesData?.results);
    setCreditNotesList(creditNotesData?.results);
  };

  return (
    <>
      {isEmbed && pdfURL && (
        <Box>
          <>
            <Box sx={{ my: 2, textAlign: "right" }}>
              <Button
                variant="contained"
                onClick={() => {
                  handlePDFClose();
                }}
              >
                Close
              </Button>
            </Box>
            <embed
              src={pdfURL}
              type="application/pdf"
              width="100%"
              height="1000px"
            />
          </>
        </Box>
      )}

      {!isEmbed && !pdfURL && (
        <>
          {(popUpShow.creditNotes || popUpShow.invoices || popUpShow.transactions || popUpShow.bankData) && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, 0.8)',
                display: 'flex',
                flexDirection: 'column', // Ändere die Ausrichtung auf Spalte
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              {popUpShow.bankData && (
                <Typography variant="h3" color="white" mb={3}>
                  get new transactions
                </Typography>
              )}
              {popUpShow.transactions && (
                <Typography variant="h3" color="white" mb={3}>
                  get transactions
                </Typography>
              )}
              {popUpShow.creditNotes && (
                <Typography variant="h3" color={popUpShow.creditNotes ? "white" : "green"} mb={3}>
                  get new creditnotes
                </Typography>
              )}
              {popUpShow.invoices && (
                <Typography variant="h3" color={popUpShow.invoices ? "white" : "green"} mb={3}>
                  get new invoices
                </Typography>
              )}
              {popUpShow.getUpdateBank && (
                <Typography variant="h3" color={popUpShow.invoices ? "white" : "green"} mb={3}>
                  wait for finish update
                </Typography>
              )}
              <CircularProgress
                color="success"
                size={100}
              />
            </div>
          )}
          <ReusableSnackbar
            className={styles.toast_msg}
            open={customSnackbar.open}
            autoHideDuration={3000}
            onClose={handleToastClose}
            message={customSnackbar.toast_message}
            severity={customSnackbar.severity}
          />
          <Grid className={styles.top_bar} container spacing={2}>
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Box className={styles.search_box}>
                <FormControl className={styles.form_box} fullWidth>
                  <OutlinedInput
                    className={styles.date}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    placeholder={t("invoiceSearchHeader")}
                    value={filters.searchQuery}
                    onChange={(event) => {
                      handleChangeSearchQuery(event);
                    }}
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControl fullWidth className={styles.form_box}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  className={styles.date}
                >
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label={t("invoiceStartDate")}
                      slotProps={{
                        textField: { fullWidth: true, error: false },
                      }}
                      value={startDate}
                      onChange={(startDate) => handleChangeStartDate(startDate)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControl fullWidth className={styles.form_box}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer fullWidth components={["DatePicker"]}>
                    <DatePicker
                      label={t("invoiceEndDate")}
                      slotProps={{
                        textField: { fullWidth: true, error: false },
                      }}
                      value={endDate}
                      onChange={(endDate) => handleChangeEndDate(endDate)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Box sx={{ display: "flex", gap: "15px" }}>
                <FormControl fullWidth className={styles.form_box}>
                  <InputLabel id="demo-simple-select-label">
                    {t("Services")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder="Choose bank account..."
                    label="Services"
                    value={filters.serviceType}
                    onChange={(event) => handleChangeServiseType(event)}
                  >
                    <MenuItem value="ALL">{t("invoiceAll")}</MenuItem>
                    <MenuItem value="BANK">BANK</MenuItem>
                    <MenuItem value="PAYPAL">PAYPAL</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth className={styles.form_box}>
                  <InputLabel id="demo-simple-select-label">
                    {t("currency")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder="Choose currency..."
                    label="Currency"
                    value={filters.currencyType}
                    onChange={(event) => handleChangeCurrencyType(event)}
                  >
                    <MenuItem value="ALL">{t("invoiceAll")}</MenuItem>
                    {currencyLists?.map((item, i) => (
                      <MenuItem key={i} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  className={styles.clear_filter}
                  size="medium"
                  color="error"
                  onClick={clearAllFilteres}
                >
                  <DeleteOutlineOutlinedIcon />
                  {t("invoiceClear")}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box className={styles.transaction_list_wrp}>
            <Grid
              container
              spacing={2}
              className={styles.transaction_list_main}
            >
              <Grid item md={6} lg={3} sm={12} className={styles.leftcolumn}>
                <Grid container spacing={2} className={styles.leftcolumninner}>
                  <Box className={styles.transaction_box}>
                    <Typography
                      className={styles.transaction_heading}
                      variant="h3"
                      sx={{ textAlign: "center", margin: "5px 0px" }}
                    >
                      {t("Transactions")}
                    </Typography>
                  </Box>
                  <Box className={styles.transaction_heading}>
                    <Box className={styles.transactionsTabWrp}>
                      <Box
                        className={
                          !showList && !showCompleted
                            ? styles.transactionsItems.active
                            : styles.transactionsItems
                        }
                        onClick={() => {
                          ShowList();
                        }}
                      >
                        <ReceiptLongIcon />
                        {t("open")}
                      </Box>
                      <Box
                        className={
                          !showList && showCompleted
                            ? styles.transactionsItems.active
                            : styles.transactionsItems
                        }
                        onClick={() => {
                          ShowCompletedList();
                        }}
                      >
                        <ReceiptLongIcon />
                        {t("completed")}
                      </Box>
                      <Box
                        className={
                          showList
                            ? styles.transactionsItems.active
                            : styles.transactionsItems
                        }
                        onClick={() => {
                          ShowHideList();
                        }}
                      >
                        <Badge
                          className={styles.badgeContentWrp}
                          badgeContent={`${hideList?.length}`}
                          color="primary"
                        > 
                          <ReceiptLongIcon />
                          {t("hidden")}
                        </Badge>
                      </Box>
                    </Box>
                  </Box>
                  {isTransactionsLoading && <CustomSkeleton />}
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    sx={{
                      height: "500px",
                      overflow: "auto",
                    }}
                  >
                    {showList && hideList && hideList?.length > 0 ? (
                      hideList.map((item, key) => (
                        <Box key={key}>
                          <Grid item xs={12} md={12} key={key}>
                            <Box className={styles.transaction_card}>
                              <Box className={styles.transaction_card_top}>
                                <Box className={styles.transaction_id}>
                                  <Typography component={"span"}>
                                    {item?.bank_id
                                      ? item?.valid_date
                                      : item?.bank_booking_date}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                  }}
                                  className={styles.transaction_amount}
                                >
                                  <Box>
                                    <Typography
                                      variant="h3"
                                      style={{
                                        textDecoration: item?.open_amount
                                          ? "line-through"
                                          : "none",
                                        color: item?.open_amount
                                          ? "grey"
                                          : "black",
                                      }}
                                    >
                                      EUR{" "}
                                      {item?.amount_in_euros?.toFixed(2) ||
                                        item?.eur_summary?.toFixed(2) ||
                                        ""}
                                    </Typography>
                                  </Box>
                                  <Typography
                                    variant="h5"
                                    style={{
                                      textDecoration: item?.open_amount
                                        ? "line-through"
                                        : "none",
                                      color: "green",
                                    }}
                                  >
                                    {item?.amount_in_foreign_currency ? (
                                      <>
                                        {item?.currency}{" "}
                                        {item?.amount_in_foreign_currency?.toFixed(
                                          2
                                        )}
                                      </>
                                    ) : item?.currency_summary ? (
                                      <>
                                        {" "}
                                        {item?.currency
                                          ? item?.currency
                                          : "EUR"}{" "}
                                        {item?.currency_summary?.toFixed(2)}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Typography>
                                  <Typography variant="h5">
                                    {item?.open_amount ? (
                                      <>
                                        {" "}
                                        {item?.currency
                                          ? item?.currency
                                          : "EUR "}{" "}
                                        {item?.open_amount?.toFixed(2)}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className={styles.transaction_card_body}>
                                <Box>
                                  <Box className={styles.transaction_method}>
                                    <Typography variant="h6">
                                      {item?.client_name ? (
                                        item?.client_name?.length >= 20 ? (
                                          <>
                                            {" "}
                                            <AccountBalanceIcon />{" "}
                                            {item?.client_name
                                              .slice(0, 20)
                                              .concat("..")}
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <AccountBalanceIcon />{" "}
                                            {item?.client_name}
                                          </>
                                        )
                                      ) : item?.user_details?.length >= 20 ? (
                                        <>
                                          <FaPaypal />
                                          {item?.user_details
                                            .slice(0, 20)
                                            .concat("..")}{" "}
                                        </>
                                      ) : (
                                        <>
                                          <FaPaypal />
                                          {item?.user_details}
                                        </>
                                      )}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    {item?.bank_id ? (
                                      textView.more &&
                                        item?.bank_id === textView?.itemId ? (
                                        <>
                                          {item?.reference}
                                          <Box
                                            onClick={() =>
                                              handleLess(item?.bank_id)
                                            }
                                            sx={{ cursor: "pointer" }}
                                          >
                                            <RemoveCircleIcon />
                                          </Box>
                                        </>
                                      ) : item?.reference?.length >= 30 ? (
                                        <>
                                          {item?.reference.slice(0, 30)}..
                                          <Box
                                            onClick={() =>
                                              handleMore(item?.bank_id)
                                            }
                                            sx={{ cursor: "pointer" }}
                                          >
                                            <AddCircleIcon />
                                          </Box>
                                        </>
                                      ) : (
                                        item?.reference
                                      )
                                    ) : textView.more &&
                                      item?.id === textView?.itemId ? (
                                      <>
                                        {item?.purpose}
                                        <Box
                                          onClick={() => handleLess(item?.id)}
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <RemoveCircleIcon />
                                        </Box>
                                      </>
                                    ) : item?.purpose?.length >= 30 ? (
                                      <>
                                        {item?.purpose.slice(0, 30)}..
                                        <Box
                                          onClick={() => handleMore(item?.id)}
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <AddCircleIcon />
                                        </Box>
                                      </>
                                    ) : (
                                      item?.purpose
                                    )}
                                  </Box>
                                </Box>
                                <Box className={styles.card_dropdown}>
                                  <Box>
                                    <IconButton
                                      size="large"
                                      aria-label="account of current user"
                                      aria-controls="menu-appbar"
                                      aria-haspopup="true"
                                      onClick={(e) =>
                                        handleMenu(
                                          e,
                                          item?.bank_id
                                            ? item?.bank_id
                                            : item?.id
                                        )
                                      }
                                      color="inherit"
                                    >
                                      <MoreHorizIcon />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Box>
                      ))
                    ) : showList &&
                      (hideList === undefined || hideList?.length <= 0) ? (
                      <>
                        <h1>{t("noDataFound")}</h1>
                      </>
                    ) : (
                      ""
                    )}
                    {!isTransactionsLoading &&
                      !showList &&
                      List &&
                      List?.length > 0 ? (
                      List.map((item, key) => (
                        <Box key={key}>
                          <Grid item xs={12} md={12} key={key}>
                            <Box className={styles.transaction_card}>
                              <Box
                                sx={{
                                  cursor: showCompleted ? "default" : "pointer",
                                }}
                                onClick={() => {
                                  !showCompleted &&
                                    addSelectedTrans(
                                      item?.bank_id ? item?.bank_id : item?.id
                                    );
                                }}
                                className={styles.transaction_card_top}
                              >
                                <Box sx={{
                                  display: "flex",
                                  justifyContent: "space-between"
                                }} className={styles.transaction_id}>
                                  <Typography component={"span"}>
                                    {item?.bank_id
                                      ? item?.valid_date
                                      : item?.bank_booking_date}
                                  </Typography>
                                  {!showCompleted && (
                                    <CheckCircleIcon
                                      fontSize="medium"
                                      color={`${rightCheckedValues.includes(
                                        item?.bank_id
                                          ? item?.bank_id
                                          : item?.id
                                      )
                                        ? "success"
                                        : "disabled"
                                        }`}
                                    />
                                  )}
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                  }}
                                  className={styles.transaction_amount}
                                >
                                  {item.amount_in_euros && item?.amount_in_euros != undefined && <Typography
                                    variant="h3"
                                    style={{
                                      textDecoration: item?.open_amount
                                        ? "line-through"
                                        : "none",
                                      color: item?.open_amount
                                        ? "grey"
                                        : "black",
                                    }}
                                  >
                                    {item.amount_in_euros && item?.amount_in_euros != undefined &&
                                      "EUR " + item?.amount_in_euros?.toFixed(2)}
                                    {/* {  item?.amount_in_euros?.toFixed(2)} */}
                                  </Typography>}

                                  {item.currency_summary < 1 && item.currency_summary != undefined && <Typography
                                    variant="h3"
                                    style={{
                                      textDecoration: item?.open_amount
                                        ? "line-through"
                                        : "none",
                                      color: item?.open_amount
                                        ? "grey"
                                        : "black",
                                    }}
                                  >
                                    {item.currency_summary < 1 && item.currency_summary != undefined &&
                                      "EUR " + item?.eur_summary?.toFixed(2)}
                                  </Typography>}
                                  {/* {!showCompleted && (
                                    <CheckCircleIcon
                                      fontSize="medium"
                                      color={`${rightCheckedValues.includes(
                                        item?.bank_id
                                          ? item?.bank_id
                                          : item?.id
                                      )
                                        ? "success"
                                        : "disabled"
                                        }`}
                                    />
                                  )} */}
                                </Box>
                                <Typography
                                  variant="h5"
                                  style={{
                                    textDecoration: item?.open_amount
                                      ? "line-through"
                                      : "none",
                                    color: "green",
                                  }}
                                >
                                  {item?.amount_in_foreign_currency &&
                                    <>
                                      {item?.currency}{" "}
                                      {item?.amount_in_foreign_currency?.toFixed(
                                        2
                                      )}
                                    </>}
                                  {item?.currency_summary != 0 && item?.currency_summary != undefined &&
                                    <>
                                      {" "}
                                      {item?.currency
                                        ? item?.currency
                                        : "EUR"}{" "}
                                      {item?.currency_summary?.toFixed(2)}
                                    </>}

                                </Typography>
                                <Typography variant="h5">
                                  {item?.open_amount ? (
                                    <>
                                      {" "}
                                      {item?.currency
                                        ? item?.currency
                                        : "EUR"}{" "}
                                      {item?.open_amount?.toFixed(2)}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Typography>
                              </Box>
                              <Box className={styles.transaction_card_body}>
                                <Box>
                                  <Box className={styles.transaction_method}>
                                    <Typography variant="h6">
                                      {item?.client_name ? (
                                        item?.client_name?.length >= 20 ? (
                                          <>
                                            {" "}
                                            <AccountBalanceIcon />{" "}
                                            {item?.client_name
                                              .slice(0, 20)
                                              .concat("..")}
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <AccountBalanceIcon />{" "}
                                            {item?.client_name}
                                          </>
                                        )
                                      ) : item?.user_details?.length >= 20 ? (
                                        <>
                                          <FaPaypal />
                                          {item?.user_details
                                            .slice(0, 20)
                                            .concat("..")}{" "}
                                        </>
                                      ) : (
                                        <>
                                          <FaPaypal />
                                          {item?.user_details}
                                        </>
                                      )}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    {item?.bank_id ? (
                                      textView.more &&
                                        item?.bank_id === textView?.itemId ? (
                                        <>
                                          {item?.reference}
                                          <Box
                                            onClick={() =>
                                              handleLess(item?.bank_id)
                                            }
                                            sx={{ cursor: "pointer" }}
                                          >
                                            <RemoveCircleIcon />
                                          </Box>
                                        </>
                                      ) : item?.reference?.length >= 30 ? (
                                        <>
                                          {item?.reference.slice(0, 30)}..
                                          <Box
                                            onClick={() =>
                                              handleMore(item?.bank_id)
                                            }
                                            sx={{ cursor: "pointer" }}
                                          >
                                            <AddCircleIcon />
                                          </Box>
                                        </>
                                      ) : (
                                        item?.reference
                                      )
                                    ) : textView.more &&
                                      item?.id === textView?.itemId ? (
                                      <>
                                        {item?.purpose}
                                        <Box
                                          onClick={() => handleLess(item?.id)}
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <RemoveCircleIcon />
                                        </Box>
                                      </>
                                    ) : item?.purpose?.length >= 30 ? (
                                      <>
                                        {item?.purpose.slice(0, 30)}..
                                        <Box
                                          onClick={() => handleMore(item?.id)}
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <AddCircleIcon />
                                        </Box>
                                      </>
                                    ) : (
                                      item?.purpose
                                    )}
                                  </Box>
                                </Box>
                                <Box className={styles.card_dropdown}>
                                  <Box>
                                    <IconButton
                                      size="large"
                                      aria-label="account of current user"
                                      aria-controls="menu-appbar"
                                      aria-haspopup="true"
                                      onClick={(e) =>
                                        handleMenu(
                                          e,
                                          item?.bank_id
                                            ? item?.bank_id
                                            : item?.id
                                        )
                                      }
                                      color="inherit"
                                    >
                                      <MoreHorizIcon />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Box>
                      ))
                    ) : (List?.length <= 0 || List === undefined) &&
                      !showList &&
                      !isTransactionsLoading ? (
                      <>
                        <h1>{t("noDataFound")}</h1>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => {
                          ShowAllTransactions();
                        }}
                      >
                        Show all Transactions
                      </Button>

                    )}
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          handleHide();
                        }}
                      >
                        {!showList ? t("Hide") : t("Show")}
                      </MenuItem>
                      {/* <MenuItem onClick={handleClose}>{t("MoneyTransition")}</MenuItem> */}
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item md={0} lg={1} className={styles.padleftremove}></Grid> */}
              <Grid item md={5} lg={4} className={styles.transaction_list_box}>
                <Grid container spacing={2} className={styles.transaction_box}>
                  <Grid item xs={12} lg={12} md={12}>
                    <Box className={styles.open_amount}>
                      <Typography className={styles.OpenAmount}>
                        {t("OpenAmount")} ={" "}
                        {tempFilteredTransArr && tempFilteredTransArr.bank_id
                          ? showOpenAmount?.toFixed(2)
                          : tempFilteredTransArr &&
                            tempFilteredTransArr?.open_amount
                            ? showOpenAmount?.toFixed(2)
                            : filteredInvoiceArr?.length > 0
                              ? showOpenAmount?.toFixed(2)
                              : "0.00"}
                      </Typography>
                      <Typography className={styles.OpenAmount}>
                        {t("unPaidAmount")} = {showUnpaidAmount?.toFixed(2)}
                      </Typography>
                    </Box>
                    <Box className={styles.confirmbutton}>
                      <Button
                        className={
                          filteredInvoiceArr?.length > 0
                            ? styles.submit_btn
                            : styles.disable_submit_button
                        }
                        variant="contained"
                        onClick={() => handleShowConfirmModal()}
                      >
                        {t("Confirm")}
                      </Button>
                    </Box>
                    <Box className={styles.ads}>
                      <Box className={styles.open_amount_card_wrp}>
                        {filteredInvoiceArr?.length > 0 &&
                          isShowSelectedInvoice &&
                          filteredInvoiceArr.map((item, key) => {
                            return (
                              <Box
                                key={key}
                                className={styles.selected_transaction_right}
                                onClick={() => handleSelectedInvoice(item.id)}
                              >
                                <Box
                                  className={styles.selected_transaction_card}
                                >
                                  <Typography>
                                    <b>Amount</b>
                                    EUR {item.invoice_number ? "" : "-"}
                                    {item?.amount_in_foreign_currency
                                      ? item?.amount_in_foreign_currency !=
                                        item?.unpaid_amount
                                        ? item?.unpaid_amount
                                        : item?.amount_in_foreign_currency?.toFixed(
                                          2
                                        )
                                      : item?.amount_in_euros !=
                                        item?.unpaid_amount
                                        ? item?.unpaid_amount
                                        : item?.amount_in_euros?.toFixed(2)}
                                  </Typography>
                                  <Typography>
                                    <b>Date</b>
                                    {item?.invoice_date ||
                                      item?.credit_note_date}
                                  </Typography>
                                  <Typography>
                                    <b>Ref no</b>
                                    {item?.invoice_number ||
                                      item?.credit_note_number}
                                  </Typography>
                                </Box>
                                <Box className={styles.selected_icon_box}>
                                  <CheckCircleIcon
                                    fontSize="medium"
                                    color={`${selectedInvoiceCheckedValue.includes(
                                      item.id
                                    )
                                      ? "success"
                                      : "disabled"
                                      }`}
                                  />
                                </Box>
                              </Box>
                            );
                          })}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {
                <>
                  <Grid
                    item
                    md={6}
                    lg={1}
                    className={styles.transaction_add_remove}
                  >
                    <Box className={styles.transaction_arrow_icon}>
                      <Box>
                        <Button
                          variant="contained"
                          className={
                            tempFilteredInvoiceArr?.length > 0
                              ? styles.active_button
                              : styles.disable_button
                          }
                          onClick={handleAddInvoice}
                        >
                          {t("Add")}
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          className={
                            tempRemoveFilteredInvoiceArr?.length > 0
                              ? styles.active_button
                              : styles.disable_button
                          }
                          onClick={removeSelectedInvoice}
                        >
                          {t("Remove")}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    lg={4}
                    sm={12}
                    className={styles.rightcolumn}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        className={styles.transaction_invoice_box}
                      >
                        <Box className={styles.tab_wrp}>
                          <Box className={styles.transaction_box}>
                            <Typography
                              className={styles.transaction_heading}
                              variant="h3"
                              sx={{ textAlign: "center", margin: "5px 0px" }}
                            >
                              SEVDESK
                            </Typography>
                          </Box>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              className={styles.tabs_top}
                              value={value}
                              onChange={handleChange}
                              aria-label="basic tabs example"
                            >
                              <Tab
                                className={styles.tab}
                                icon={<AssignmentTurnedInTwoToneIcon />}
                                label={t("allDocuments")}
                                {...a11yProps(0)}
                              />

                              <Tab
                                className={styles.tab}
                                icon={<DescriptionIcon />}
                                label={t("invocieInvoices")}
                                {...a11yProps(1)}
                              />

                              <Tab
                                className={styles.tab}
                                icon={<DocumentScannerTwoToneIcon />}
                                label={t("CreditNotes")}
                                {...a11yProps(2)}
                              />
                            </Tabs>
                          </Box>
                          <TabPanel
                            className={styles.pad0}
                            value={value}
                            index={0}
                          >
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                height: "500px",
                                overflow: "auto",
                              }}
                              className={styles.transaction_list_box}
                            >
                              {isGetInvoiceDataLoading && isGetCreditNotesDataLoading && <CustomSkeleton />}
                              {!isGetInvoiceDataLoading &&
                                !isGetInvoiceDataError &&
                                invoicesList &&
                                invoicesList?.length > 0 &&
                                invoicesList?.map((item, key) => (
                                  <Grid item xs={12} key={key}>
                                    <Box
                                      className={`${styles.transaction_card} ${currentCardEntries &&
                                        currentCardEntries?.length >= 1
                                        ? ""
                                        : styles.transaction_card_active
                                        }`}
                                    >
                                      <Box
                                        className={styles.transaction_card_top}
                                        onClick={(event) => handleClickEntries(item.id, event)}
                                      >
                                        <Box className={styles.transaction_id}>
                                          <Typography component={"span"}>
                                            {item?.invoice_date}
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Box
                                            className={
                                              styles.transaction_amount
                                            }
                                          >
                                            <Typography
                                              variant="h3"
                                              style={{
                                                textDecoration:
                                                  item?.amount_in_foreign_currency !== 0
                                                    ? "none"
                                                    : item?.unpaid_amount !== item?.amount_in_euros
                                                      ? "line-through"
                                                      : "none",
                                                color:
                                                  item?.unpaid_amount !== item?.amount_in_euros
                                                    ? "grey"
                                                    : "black",
                                                fontSize:
                                                  item?.amount_in_foreign_currency !== 0
                                                    ? "80%" 
                                                    : "inherit"
                                              }}
                                            >
                                              EUR {item?.amount_in_euros?.toFixed(2)}
                                            </Typography>


                                            <Typography
                                              variant="h5"
                                              style={{
                                                textDecoration:
                                                  item?.unpaid_amount !== item?.amount_in_euros
                                                    ? "line-through"
                                                    : "none",
                                                color: "blue",
                                              }}
                                            >
                                              {item?.amount_in_foreign_currency && item?.unpaid_amount > item?.amount_in_foreign_currency ? (
                                                <>
                                                  {item?.currency} {item?.amount_in_foreign_currency?.toFixed(2)}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </Typography>
                                            <Typography
                                              variant="h5"
                                              style={{
                                                color:
                                                  item?.currency !== "EUR"
                                                    ? "green"
                                                    : "black",
                                              }}
                                            >
                                              {/* {item?.open_amount ? <> {item?.currency} {item?.open_amount?.toFixed(2)}</> : item?.eur_summary ? <> {item?.currency ? item?.currency : "EUR"} {item?.eur_summary?.toFixed(2)}</> : ""} */}
                                              {item?.unpaid_amount !==
                                                item?.amount_in_euros ? (
                                                <>
                                                  {" "}
                                                  {item?.currency
                                                    ? item?.currency
                                                    : "EUR"}{" "}
                                                  {item?.unpaid_amount?.toFixed(
                                                    2
                                                  )}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </Typography>
                                          </Box>
                                          <CheckCircleIcon
                                            fontSize="medium"
                                            color={`${checkedValues.includes(item.id)
                                              ? "success"
                                              : "disabled"
                                              }`}
                                          />
                                        </Box>
                                      </Box>
                                      <Box
                                        className={styles.transaction_card_body}
                                      >
                                        <Box
                                          className={styles.transaction_method}
                                        >
                                          <Typography variant="h6">
                                            <AccountBalanceIcon />{" "}
                                            {item?.contact_company_name}
                                          </Typography>
                                          <Typography variant="subtitle2">
                                            {item?.invoice_number}
                                          </Typography>
                                        </Box>
                                        <Button
                                          className={styles.pdf_btn}
                                          variant="outlined"
                                          onClick={() => {
                                            InvoiceDownload(item.sevdesk_id);
                                          }}
                                        >
                                          {t("ShowPdf")}
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Grid>
                                ))}
                              {!isGetCreditNotesDataLoading &&
                                !isGetCreditNotesDataError &&
                                creditNotesList?.length > 0 &&
                                creditNotesList?.map((item, key) => (
                                  <Grid item xs={12} key={key}>
                                    <Box
                                      className={`${styles.transaction_card} ${currentCardEntries &&
                                        currentCardEntries?.length >= 1
                                        ? ""
                                        : styles.transaction_card_active
                                        }`}
                                    >
                                      <Box
                                        className={styles.transaction_card_top}
                                        onClick={(event) => handleClickEntries(item.id, event)}
                                      >
                                        <Box className={styles.transaction_id}>
                                          <Typography component={"span"}>
                                            {item?.credit_note_date}
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Box
                                            className={
                                              styles.transaction_amount
                                            }
                                          >
                                            <Typography variant="h3">
                                              EUR -
                                              {item?.amount_in_euros?.toFixed(
                                                2
                                              )}
                                            </Typography>

                                            <Typography
                                              variant="h3"
                                              style={{ color: "green" }}
                                            >
                                              {item.amount_in_foreign_currency ? (
                                                <>
                                                  {" "}
                                                  {item?.currency} -
                                                  {item?.amount_in_foreign_currency?.toFixed(
                                                    2
                                                  )}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </Typography>
                                          </Box>
                                          <CheckCircleIcon
                                            fontSize="medium"
                                            color={`${checkedValues.includes(item.id)
                                              ? "success"
                                              : "disabled"
                                              }`}
                                          />
                                        </Box>
                                      </Box>
                                      <Box
                                        className={styles.transaction_card_body}
                                      >
                                        <Box
                                          className={styles.transaction_method}
                                        >
                                          <Typography variant="h6">
                                            <AccountBalanceIcon />{" "}
                                            {item?.contact_company_name}
                                          </Typography>
                                          <Typography variant="subtitle2">
                                            {item?.credit_note_number}
                                          </Typography>
                                        </Box>
                                        <Button
                                          className={styles.pdf_btn}
                                          variant="outlined"
                                          onClick={() => {
                                            CreditNoteDownload(item.sevdesk_id);
                                          }}
                                        >
                                          {t("ShowPdf")}
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Grid>
                                ))}
                              {(invoicesList?.length > 0 &&
                                !isGetInvoiceDataError && !isGetInvoiceDataLoading) ||
                                (creditNotesList?.length > 0 &&
                                  !isGetCreditNotesDataError && !isGetCreditNotesDataLoading) ? (
                                ""
                              ) : (
                                <>
                                  <h1>{t("noDataFound")}</h1>
                                </>
                              )}
                            </Grid>
                          </TabPanel>
                          <TabPanel
                            className={styles.pad0}
                            value={value}
                            index={1}
                          >
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                height: "500px",
                                overflow: "auto",
                              }}
                              className={styles.transaction_list_box}
                            >
                              {isGetInvoiceDataLoading && <CustomSkeleton />}

                              {invoicesList?.length > 0 &&
                                !isGetInvoiceDataError ? (
                                invoicesList?.map((item, key) => {
                                  return (
                                    <Grid item xs={12} key={key}>
                                      <Box
                                        className={`${styles.transaction_card
                                          } ${currentCardEntries &&
                                            currentCardEntries?.length >= 1
                                            ? ""
                                            : styles.transaction_card_active
                                          }`}
                                      >
                                        <Box
                                          className={
                                            styles.transaction_card_top
                                          }
                                          onClick={(event) => handleClickEntries(item.id, event)}
                                        >
                                          <Box
                                            className={styles.transaction_id}
                                          >
                                            <Typography component={"span"}>
                                              {item?.invoice_date}
                                            </Typography>
                                          </Box>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box
                                              className={
                                                styles.transaction_amount
                                              }
                                            >
                                              <Typography
                                                variant="h3"
                                                style={{
                                                  textDecoration:
                                                    item?.unpaid_amount !==
                                                      item?.amount_in_euros
                                                      ? "line-through"
                                                      : "none",
                                                  color:
                                                    item?.unpaid_amount !==
                                                      item?.amount_in_euros
                                                      ? "grey"
                                                      : "black",
                                                }}
                                              >
                                                EUR{" "}
                                                {item?.amount_in_euros?.toFixed(
                                                  2
                                                )}
                                              </Typography>

                                              <Typography
                                                variant="h5"
                                                style={{
                                                  textDecoration:
                                                    item?.unpaid_amount !==
                                                      item?.amount_in_euros
                                                      ? "line-through"
                                                      : "none",
                                                  color: "green",
                                                }}
                                              >
                                                {item?.amount_in_foreign_currency ? (
                                                  <>
                                                    {item?.currency}{" "}
                                                    {item?.amount_in_foreign_currency?.toFixed(
                                                      2
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </Typography>
                                              <Typography
                                                variant="h5"
                                                style={{
                                                  color:
                                                    item?.currency !== "EUR"
                                                      ? "green"
                                                      : "black",
                                                }}
                                              >
                                                {/* {item?.open_amount ? <> {item?.currency} {item?.open_amount?.toFixed(2)}</> : item?.eur_summary ? <> {item?.currency ? item?.currency : "EUR"} {item?.eur_summary?.toFixed(2)}</> : ""} */}
                                                {item?.unpaid_amount !==
                                                  item?.amount_in_euros ? (
                                                  <>
                                                    {" "}
                                                    {item?.currency
                                                      ? item?.currency
                                                      : "EUR"}{" "}
                                                    {item?.unpaid_amount?.toFixed(
                                                      2
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </Typography>
                                            </Box>
                                            <CheckCircleIcon
                                              fontSize="medium"
                                              color={`${checkedValues.includes(item.id)
                                                ? "success"
                                                : "disabled"
                                                }`}
                                            />
                                          </Box>
                                        </Box>
                                        <Box
                                          className={
                                            styles.transaction_card_body
                                          }
                                        >
                                          <Box
                                            className={
                                              styles.transaction_method
                                            }
                                          >
                                            <Typography variant="h6">
                                              <AccountBalanceIcon />{" "}
                                              {item?.contact_company_name}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                              {item?.invoice_number}
                                            </Typography>
                                          </Box>
                                          <Button
                                            className={styles.pdf_btn}
                                            variant="outlined"
                                            onClick={() => {
                                              InvoiceDownload(item.sevdesk_id);
                                            }}
                                          >
                                            {t("ShowPdf")}
                                          </Button>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  );
                                })
                              ) : (
                                <>
                                  <h1>{t("noDataFound")}</h1>
                                </>
                              )}
                            </Grid>
                          </TabPanel>

                          <TabPanel
                            className={styles.pad0}
                            value={value}
                            index={2}
                          >
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                height: "500px",
                                overflow: "auto",
                              }}
                              className={styles.transaction_list_box}
                            >
                              {isGetCreditNotesDataLoading && <CustomSkeleton />}

                              {creditNotesList?.length > 0 &&
                                !isGetInvoiceDataError ? (
                                creditNotesList?.map((item, key) => {
                                  return (
                                    <Grid item xs={12} key={key}>
                                      <Box
                                        className={`${styles.transaction_card
                                          } ${currentCardEntries &&
                                            currentCardEntries?.length >= 1
                                            ? ""
                                            : styles.transaction_card_active
                                          }`}
                                      >
                                        <Box
                                          className={
                                            styles.transaction_card_top
                                          }
                                          onClick={(event) => handleClickEntries(item.id, event)}
                                        >
                                          <Box
                                            className={styles.transaction_id}
                                          >
                                            <Typography component={"span"}>
                                              {item?.credit_note_date}
                                            </Typography>
                                          </Box>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box
                                              className={
                                                styles.transaction_amount
                                              }
                                            >
                                              <Typography variant="h3">
                                                EUR -
                                                {item?.amount_in_euros?.toFixed(
                                                  2
                                                )}
                                              </Typography>

                                              <Typography
                                                variant="h3"
                                                style={{ color: "green" }}
                                              >
                                                {item.amount_in_foreign_currency ? (
                                                  <>
                                                    {" "}
                                                    {item?.currency} -
                                                    {item?.amount_in_foreign_currency?.toFixed(
                                                      2
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </Typography>
                                            </Box>
                                            <CheckCircleIcon
                                              fontSize="medium"
                                              color={`${checkedValues.includes(item.id)
                                                ? "success"
                                                : "disabled"
                                                }`}
                                            />
                                          </Box>
                                        </Box>
                                        <Box
                                          className={
                                            styles.transaction_card_body
                                          }
                                        >
                                          <Box
                                            className={
                                              styles.transaction_method
                                            }
                                          >
                                            <Typography variant="h6">
                                              <AccountBalanceIcon />{" "}
                                              {item?.contact_company_name}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                              {item?.credit_note_number}
                                            </Typography>
                                          </Box>
                                          <Button
                                            className={styles.pdf_btn}
                                            variant="outlined"
                                            onClick={() => {
                                              CreditNoteDownload(
                                                item.sevdesk_id
                                              );
                                            }}
                                          >
                                            {t("ShowPdf")}
                                          </Button>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  );
                                })
                              ) : (
                                <>
                                  <h1>{t("noDataFound")}</h1>
                                </>
                              )}
                            </Grid>
                          </TabPanel>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
              <Grid mt={4}>
                <Button
                  variant="contained"
                  onClick={() => {
                    updateBankdata();
                  }}
                >
                  Update Bankdata
                </Button>
              </Grid>
            </Grid>
          </Box>

          <ConfirmModal
            open={confirmModal}
            handlesuccess={handleSuccessConfirmModal}
            handleclose={handleclose}
            message={
              confirmHide === true ? (
                ""
              ) : (
                <>
                  <label>
                    {" "}
                    {t("OpenAmount")} = {showOpenAmount?.toFixed(2)}{" "}
                  </label>
                  <br />
                  <label>
                    {" "}
                    {t("unPaidAmount")} = {showUnpaidAmount?.toFixed(2)}
                  </label>
                </>
              )
            }
            header={
              confirmHide === true
                ? !showList
                  ? t("askForHide")
                  : t("askForShow")
                : t("quesForModel")
            }
          />
        </>
      )}
    </>
  );
};

export default Transaction;
