import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { t } from 'i18next';
import { Box } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmModal = ({ open, handlesuccess, handleclose, message, header }) => {
    return (
        <Box>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleclose}
                aria-describedby="alert-dialog-slide-description"
            >
                {/* <DialogTitle>{"Are you sure with following invoices/credit-notes?"}</DialogTitle> */}
                <DialogTitle>{header}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color="success" onClick={handlesuccess}>{t("yes")}</Button>
                    <Button color="error" variant='outlined' onClick={handleclose}>{t("no")}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default ConfirmModal