import { apiSlice } from "../api.slice";

export const creditNotesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        creditNotes: builder.query({
            query: () => ({
                url: "/api/sevdesk/credit-notes/",
                method: "GET",
            }),
        }),
        getCreditNotes: builder.query({
            query: (transId) => ({
                url: `/api/sevdesk/credit-notes/${transId ? `?transaction_id=${transId}` : ""}`,
                method: "GET",
            }),
        }),
        getCreditNotesPDF: builder.query({
            query: (id) => ({
                url: `/api/sevdesk/credit-note/${id}/getPdf`,
                method: "GET",
            }),
        }),
        creditNotesUpdate: builder.mutation({
            query: (payload) => ({
                url: '/api/sevdesk/credit-notes/',
                method: 'PUT',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
        }),
        creditNotesRevert: builder.mutation({
            query: (payload) => ({
                url: '/api/sevdesk/credit-note-revert/',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
        }),
    }),
});

export const {  useLazyCreditNotesQuery, useLazyGetCreditNotesQuery, useLazyGetCreditNotesPDFQuery, useCreditNotesUpdateMutation, useCreditNotesRevertMutation } = creditNotesApiSlice;
