import { createSlice } from "@reduxjs/toolkit";
import { history } from "_helpers";

// create slice

const name = "auth";
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

// exports

export const authActions = { ...slice.actions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    // initialize state from local storage to enable user to stay logged in
    user: JSON.parse(localStorage.getItem("user")) || {},
    error: null,
  };
}

function createReducers() {
  const login = (state, action) => {
    let { access, refresh } = action.payload || {};
    state.user.access = access;
    state.user.refresh = refresh;
    state.user.isLogin = true;
    localStorage.setItem(
      "user",
      JSON.stringify({ ...action.payload, isLogin: true })
    );
    history.navigate("/");
  };

  const logout = (state) => {
    state.user = {};
    state.isLogin = false;
    localStorage.removeItem("user");
    history.navigate("/");
  };

  const registration = (state) => {
    state.user = {};
    state.isLogin = false;
    localStorage.removeItem("user");
    history.navigate("/");
  };

  return {
    login,
    logout,
    registration,
  };
}

export const { login, logout, registration, } = slice.actions;
