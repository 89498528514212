import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import * as React from "react";
import { history } from "_helpers";
import { PrivateRoute } from "_components";
import { Login } from "pages/login";
import Error401 from "pages/errors/401/401";
import Error404 from "pages/errors/404/404";
import Error500 from "pages/errors/500/500";
import Invoice from "pages/invoice/Invoice";
import CreditNotes from "pages/credit_notes/CreditNotes";
import ColorModeContext from "_components/theme";
import BankConnection from "pages/bank_connection/BankConnection";
import Transaction from "pages/transaction/Transaction";
import ForgotPassword from "pages/chnage_password/ForgotPassword";
import ResetPassword from "pages/chnage_password/ResetPassword";
import InvoicePDF from "pages/invoice/InvoicePDF";


const App = () => {
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  const [colorMode, setColorMode] = React.useState("light")
  const toggleColorMode = () => {
    setColorMode((old) => {
      return old === 'light' ? 'dark' : 'light'
    })
  }

  React.useEffect(() => {
    document.body.setAttribute("data-theme", `${colorMode}`);
  }, [colorMode])

  //Navigation states
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <ColorModeContext.Provider value={{ colorMode, toggleColorMode }}>
      <div className="app-container" >
        <Routes>
          {/* Private Routes*/}
          {/* <Route path="/" element={<PrivateRoute> <Home /> </PrivateRoute>} /> */}
          <Route path="/invoice" element={<PrivateRoute> <Invoice /> </PrivateRoute>} />
          <Route path="/download-pdf" element={<PrivateRoute> <InvoicePDF /> </PrivateRoute>} />
          <Route path="/credit-notes" element={<PrivateRoute> <CreditNotes /> </PrivateRoute>} />
          <Route path="/bank-connection" element={<PrivateRoute> <BankConnection /> </PrivateRoute>} />
          <Route path="/transactions" element={<PrivateRoute> <Transaction /> </PrivateRoute>} />


          {/* Public Routes */}
          <Route path="/" element={<Login />} />
          <Route path="/401" element={<Error401 />} />
          <Route path="/404" element={<Error404 />} />
          <Route path="/500" element={<Error500 />} />
          <Route path="*" element={<Error404 />} />

          {/* Public Routes - Password change*/}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />

        </Routes>
      </div>
    </ColorModeContext.Provider>
  );
}

export { App };