import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./auth/auth.slice";
import { usersReducer } from "./users.slice";
import { apiSlice } from "./api.slice";
import { invoiceReducer } from "./invoice/invoice.slice";
import { creditNotesReducer } from "./credit_notes/creditNotes.slice";
import { transactionReducer } from "./transaction/transaction.slice";
import { connectionReducer } from "./connection/connection.slice";

export * from "./auth/auth.slice";
export * from "./users.slice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath] : apiSlice.reducer,
    auth: authReducer,
    users: usersReducer,
    invoice: invoiceReducer,
    creditNotes: creditNotesReducer,
    transactions: transactionReducer,
    connections : connectionReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devtools: true,
});
