import * as React from "react";
import { Box, CircularProgress, Grid, Link, Tab, Tabs, Typography, } from "@mui/material";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone';
import styles from './credit_notes.module.scss'
import PropTypes from 'prop-types';
import CreditNotesDataTable from "_components/data_tables/CreditNotesDataTable";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CreditNotes = () => {
    const creditNotesData = useSelector(state => state.creditNotes.creditNotes)

    //States
    const initialFilter = {
        tab: 'Delivered',
    }
    const [filters, setFilters] = React.useState(initialFilter);
    const [value, setValue] = React.useState(2);
    const tabIndex = ['all', 'Payed', 'Delivered', 'part-paid']
    const { t } = useTranslation();

    //total count
    const allCount = creditNotesData?.length;
    const paidCount = creditNotesData?.filter((ele) => ele.status == 'Payed');
    const unpaidCount = creditNotesData?.filter((ele) => ele.status == 'Delivered');
    const partPaidCount = creditNotesData?.filter((ele) => ele.status == 'part-paid');

    const calculateTotal = (arr) => {
        const total = (arr.reduce((total, item) => item.amt + total, 0))?.toFixed(2);
        return total;
    }

    const totalAll = calculateTotal(creditNotesData);
    const paidTotal = calculateTotal(paidCount);
    const unpaidTotal = calculateTotal(unpaidCount);

    //Functions
    const handleChange = (event, newValue) => {
        setFilters((old) => ({ ...old, tab: tabIndex[newValue] }))
        setValue(newValue);
    };
    const clearFilteres = () => {
        setValue(0)
        setFilters(initialFilter)
    }

    //Others
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    return (
        <>
            <Box className={styles.page_top}>
                <Grid container spacing={2}>
                    <Grid item lg={6}>
                        <Box className={styles.page_title}>
                            <Typography variant="h5">{t('CreditNotes')}</Typography>
                        </Box>
                        <Box className={styles.page_location}>
                            <Link component={"span"}>{t('invoiceDashboard')}</Link>
                            <Typography component={"span"}> / </Typography>
                            <Link component={"span"}>{t('CreditNotes')}</Link>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box className={styles.card_wrp}>
                        <Box className={styles.progress_card}>
                            <Box className={styles.card_content_box}>
                                <Box className={styles.card_image}>
                                    <Box position="relative" display="inline-block">
                                        <Box
                                            top={0}
                                            left={0}
                                            bottom={0}
                                            right={0}
                                            position="absolute"
                                        >
                                            <CircularProgress
                                                className={styles.circular_progress_one}
                                                size={50}
                                                variant="determinate"
                                                value={25}
                                            />
                                        </Box>
                                        <CircularProgress
                                            size={50}
                                            className={styles.circular_progress_two}
                                            variant="determinate"
                                            value={100}
                                        />
                                        <Box
                                            top={0}
                                            left={0}
                                            bottom={0}
                                            right={0}
                                            position="absolute"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography
                                                variant="h5"
                                                component="div"
                                                className={styles.card_circul_box}
                                                color="textSecondary"
                                            >
                                                <NotificationsNoneTwoToneIcon />
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={styles.card_details}>
                                    <Typography variant="h6">{t('invoiceTotal')}</Typography>
                                    <Typography className={styles.card_sub_text}>
                                        <b>{allCount} </b>{t('CreditNotes')}
                                    </Typography>
                                    <Typography className={styles.card_amount} sx={{ color: "rgb(255, 86, 48)" }}>
                                        EUR {totalAll}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <hr></hr>
                        <Box className={styles.progress_card}>
                            <Box className={styles.card_content_box}>
                                <Box className={styles.card_image}>
                                    <Box position="relative" display="inline-block">
                                        <Box
                                            top={0}
                                            left={0}
                                            bottom={0}
                                            right={0}
                                            position="absolute"
                                        >
                                            <CircularProgress
                                                className={styles.circular_progress_one}
                                                size={50}
                                                variant="determinate"
                                                value={25}
                                            />
                                        </Box>
                                        <CircularProgress
                                            className={styles.circular_progress_two}
                                            size={50}
                                            variant="determinate"
                                            value={100}
                                        />
                                        <Box
                                            top={0}
                                            left={0}
                                            bottom={0}
                                            right={0}
                                            position="absolute"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography
                                                variant="h5"
                                                component="div"
                                                className={styles.card_circul_box}
                                                color="textSecondary"
                                            >
                                                <CheckCircleTwoToneIcon />
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={styles.card_details}>
                                    <Typography variant="h6">{t('invoicePaid')}</Typography>
                                    <Typography className={styles.card_sub_text}>
                                        <b>{paidCount?.length} </b>{t('CreditNotes')}
                                    </Typography>
                                    <Typography className={styles.card_amount}>
                                        EUR {paidTotal}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <hr></hr>
                        <Box className={styles.progress_card}>
                            <Box className={styles.card_content_box}>
                                <Box className={styles.card_image}>
                                    <Box position="relative" display="inline-block">
                                        <Box
                                            top={0}
                                            left={0}
                                            bottom={0}
                                            right={0}
                                            position="absolute"
                                        >
                                            <CircularProgress
                                                className={styles.circular_progress_one}
                                                size={50}
                                                variant="determinate"
                                                value={25}
                                            />
                                        </Box>
                                        <CircularProgress
                                            size={50}
                                            className={styles.circular_progress_two}
                                            variant="determinate"
                                            value={100}
                                        />
                                        <Box
                                            top={0}
                                            left={0}
                                            bottom={0}
                                            right={0}
                                            position="absolute"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography
                                                variant="h5"
                                                component="div"
                                                className={styles.card_circul_box}
                                                color="textSecondary"
                                            >
                                                <NotificationsNoneTwoToneIcon />
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={styles.card_details}>
                                    <Typography variant="h6">{t('invoiceUnpaid')}</Typography>
                                    <Typography className={styles.card_sub_text}>
                                        <b>{unpaidCount?.length} </b>{t('CreditNotes')}
                                    </Typography>
                                    <Typography className={styles.card_amount} >
                                        EUR {unpaidTotal}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box className={styles.tab_wrp}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs className={styles.tabs_top} value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab className={styles.tab} icon={<Typography className={styles.tab_count} component="span"  > {allCount} </Typography>} label={t('invoiceAll')} {...a11yProps(0)} />
                                <Tab className={styles.tab} icon={<Typography className={styles.tab_count} component="span"  > {paidCount?.length} </Typography>} label={t('invoicePaid')} {...a11yProps(1)} />
                                <Tab className={styles.tab} icon={<Typography className={styles.tab_count} component="span"  > {unpaidCount?.length} </Typography>} label={t('invoiceUnpaid')} {...a11yProps(2)} />
                                {/* <Tab className={styles.tab} icon={<Typography className={styles.tab_count} component="span"  > 3 </Typography>} label={t('invoicedue')} {...a11yProps(3)} /> */}
                                <Tab className={styles.tab} icon={<Typography className={styles.tab_count} component="span"  > {partPaidCount?.length} </Typography>} label={t('invoicePartPaid')} {...a11yProps(4)} />
                                {/* <Tab className={styles.tab} icon={<Typography className={styles.tab_count} component="span"  > {reversalCount?.length} </Typography>} label={t('invoiceReversal')} {...a11yProps(4)} /> */}
                            </Tabs>
                        </Box>
                        <CreditNotesDataTable tag={filters.tab} clearFilteres={clearFilteres} />
                    </Box>
                </Grid>
            </Grid >
        </>
    );
};

export default CreditNotes;
