import { apiSlice } from "../api.slice";

export const transactionsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        transactions: builder.query({
            query: () => ({
                url: "/api/finapibank/transaction/",
                method: "GET",
            }),
            transformResponse: (response) => {
                return response
            },
        }),
        paypal: builder.query({
            query: () => ({
                url: `/api/banking/paypal/${null}/${null}`,
                method: "GET",
            }),
            transformResponse: (response) => {
                return response
            },
        }),
        paypal_Transactions: builder.query({
            query: () => ({
                url: "/api/banking/transactions/",
                method: "GET",
            }),
            transformResponse: (response) => {
                return response
            },
        }),

    }),
});

export const { useLazyTransactionsQuery, useLazyPaypal_TransactionsQuery, useLazyPaypalQuery } = transactionsApiSlice;
