import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const ReusableSnackbar = (props) => {
  const { open, autoHideDuration, onClose, message, severity } = props;

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      message={message}
      severity={severity}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <MuiAlert severity={severity} variant="filled">
        {message}
      </MuiAlert>
    </Snackbar>
  );
};
export default ReusableSnackbar;
