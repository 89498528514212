import React from 'react'
import { Box, Typography } from '@mui/material';
import error404 from "../../../assets/images/error-404.png"
import styles from '../errors.module.scss'
import { Link } from 'react-router-dom';
const Error404 = () => {
  return (
    <Box className={styles.error_wrap}>
      <Box className={styles.error_image_box}>
        <img src={error404} alt="error404" />
      </Box>
      <Typography className={styles.error_title} variant='h4'>404: The page you are looking for isn’t here</Typography>
      <Typography className={styles.error_sub_title} variant='subtitle1'>You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.</Typography>
      <Box className={styles.error_bottom}>
        <Link to="/" className={styles.home_btn}>Back to Home</Link>
      </Box>
    </Box>
  )
}

export default Error404;