import MuiDrawer from "@mui/material/Drawer";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import { useNavigate } from "react-router-dom";
import { Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import styles from '../layout/layout.module.scss'
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import { useDispatch } from "react-redux";
import { logout } from "_store";
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import logo from "../../assets/images/logo.png"
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SettingsIcon from '@mui/icons-material/Settings';
import { apiSlice } from "_store/api.slice";
import { clrCreditNotes } from "_store/credit_notes/creditNotes.slice";
import { clrInvoices } from "_store/invoice/invoice.slice";


//Component Styles
const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",


    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),

    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));


const Sidebar = ({ open, handleDrawerClose, handleDrawerOpen, handleClick, openMenu }) => {
    //Navigation hooks
    const navigate = useNavigate();
    const dispatch = useDispatch()

    //Theme
    const theme = useTheme();

    //Submenus

    const [openMenuBanking, setOpenMenuBanking] = React.useState(true);


    const handleClickBanking = () => {
        setOpenMenuBanking(!openMenuBanking);
    };

    //Logout
    const handleLogout = () => {
        dispatch(clrCreditNotes())
        dispatch(clrInvoices())
        dispatch(apiSlice.util.resetApiState());
        dispatch(logout())
    }

    return (
        <>
            <Drawer className={styles.sideBar} variant="permanent" open={open}>
                <DrawerHeader>
                    <Box className={styles.logo_icon}>
                        <img src={logo} alt='LOGO' />
                    </Box>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowLeftIcon className={styles.leftIcon} />
                        ) : (
                            <KeyboardArrowLeftIcon className={styles.leftIcon} />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider className={styles.divider} />
                <List>
                    <ListItem className={styles.dashbord_P} key="Transactions" disablePadding >
                        <ListItemButton
                            className={styles.dashbord_item}
                            onClick={() => navigate('/transactions')}
                        >
                            <ReceiptLongIcon className={styles.listicon}>
                                <DescriptionTwoToneIcon fontSize="small" />
                            </ReceiptLongIcon>
                            <ListItemText className={styles.menu_text} primary="Transactions" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>

                    {/* <ListItem className={styles.dashbord_P} key="Dashboard" disablePadding sx={{ display: "block" }}>
                        <ListItemButton
                            className={styles.dashbord_item}

                            onClick={() => navigate('/')}
                        >
                            <ListItemIcon
                                className={styles.list_icon}>
                                <SpaceDashboardTwoToneIcon fontSize="small" />
                            </ListItemIcon>

                            <ListItemText className={styles.menu_text} primary="Dashboard" />

                        </ListItemButton>
                    </ListItem> */}

                    {/* <ListItemButton className={styles.handleclick} onClick={handleClickSevdesk}>                     
                        <ListItemIcon className={styles.list_item}>
                            <ComputerTwoToneIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText className={styles.menu_text} primary="Sevdesk" />
                        {openMenu ? <ExpandLess sx={{ color: "#9da4ae" }} /> : <ExpandMore sx={{ color: "#9da4ae" }} />}
                    </ListItemButton> */}
                    <ListItem className={styles.listItem} key="Invoice" disablePadding >
                        <ListItemButton
                            className={styles.dashbord_item}
                            onClick={() => navigate('/invoice')}
                        >
                            <ListItemIcon
                                className={styles.listicon}                                    >
                                <ReceiptTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText className={styles.menu_text} primary="Invoice" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem className={styles.dashbord_P} key="Credit Notes" disablePadding >
                        <ListItemButton
                            className={styles.dashbord_item}
                            onClick={() => navigate('/credit-notes')}
                        >
                            <ListItemIcon
                                className={styles.listicon}>
                                <DescriptionTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText className={styles.menu_text} primary="Credit Notes" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>

                    {/* <Collapse in={openMenuSevdesk} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem className={styles.listItem} key="Invoice" disablePadding >
                                <ListItemButton
                                    className={styles.dashbord_item}
                                    onClick={() => navigate('/invoice')}
                                >
                                    <ListItemIcon
                                        className={styles.listicon}                                    >
                                        <ReceiptTwoToneIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText className={styles.menu_text} primary="Invoice" sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem className={styles.dashbord_P} key="Credit Notes" disablePadding >
                                <ListItemButton
                                    className={styles.dashbord_item}
                                    onClick={() => navigate('/credit-notes')}
                                >
                                    <ListItemIcon
                                        className={styles.listicon}>
                                        <DescriptionTwoToneIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText className={styles.menu_text} primary="Credit Notes" sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse> */}
                    {/* <ListItem className={styles.dashbord_P} key="Transactions" disablePadding >
                        <ListItemButton
                            className={styles.dashbord_item}
                            onClick={() => navigate('/transactions')}
                        >
                            <ReceiptLongIcon className={styles.listicon}>
                                <DescriptionTwoToneIcon fontSize="small" />
                            </ReceiptLongIcon>
                            <ListItemText className={styles.menu_text} primary="Transactions" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem> */}
                    <ListItemButton className={styles.handleclick} onClick={handleClickBanking}>
                        <ListItemIcon className={styles.list_item}>
                            <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText className={styles.menu_text} primary="Setting" />
                        {openMenu ? <ExpandLess sx={{ color: "#9da4ae" }} /> : <ExpandMore sx={{ color: "#9da4ae" }} />}
                    </ListItemButton>

                    <Collapse in={openMenuBanking} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem className={styles.listItem} key="Bank Connection" disablePadding >
                                <ListItemButton
                                    className={styles.dashbord_item}
                                    onClick={() => navigate('/bank-connection')}
                                >
                                    <ListItemIcon className={styles.listicon}>
                                        <AccountBalanceTwoToneIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText className={styles.menu_text} primary="Bank Connection" sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>

                        </List>
                    </Collapse>
                </List>

                <List className={styles.bottom_logout} style={{ marginTop: `auto` }} >
                    <ListItemButton
                        className={styles.dashbord_item}
                        onClick={() => handleLogout()}
                    >
                        <ListItemIcon
                            className={styles.list_icon}>
                            <LogoutTwoToneIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText className={styles.menu_text} primary="Logout" />
                    </ListItemButton>
                </List>
                <Divider />
            </Drawer>
        </>
    )
}

export default Sidebar