import { createSlice } from "@reduxjs/toolkit";

// create slice
const name = "auth";
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

// exports
export const transactionActions = { ...slice.actions };
export const transactionReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        transactions: [],
        error: null,
    };
}

function createReducers() {

    const setTransactions = (state, action) => {
        state.transactions = action.payload || []
    };

    return {
        setTransactions
    };
}

export const { setTransactions } = slice.actions;
