import { apiSlice } from "../api.slice";

export const connectionApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        connections: builder.mutation({
            query: (str) => ({
                url: `/api/finapibank/bank-connection/`,
                method: "POST",
                body: { search: str }
            }),
            transformResponse: (response) => {
                return response
            },
        }),
        deleteConnections: builder.mutation({
            query: (id) => ({
                url: `/api/finapibank/bank-connection/${id}/`,
                method: "DELETE",
            }),
            transformResponse: (response) => {
                return response
            },
        }),
        getAllConnection: builder.query({
            query: () => ({
                url: `/api/finapibank/bank-connection/`,
                method: "GET",

            }),
        }),
        checkAuth: builder.query({
            query: () => ({
                url: `/api/banking/checkauth/`,
                method: "GET",
            }),
        }),
        addToken: builder.query({
            query: (data) => {
                return {
                    url: `/api/banking/paypal/${data.basic}/${data.auth}`,
                    method: "GET",
                }
            },
        }),
        connectionStatus: builder.query({
            query: (data) => {                
                return {
                    url: `/api/finapibank/bank-connection/${data}/`,
                    method: "GET",
                }
            },
        }),
        completedConnection: builder.mutation({            
            query: (data) => (                
                {                 
                url: `/api/finapibank/transaction/`,
                method: "POST",
                body: { id: data }
            }),
            
            transformResponse: (response) => {
                return response
            },
        }),
        updateBankData: builder.query({            
            query: (userIPAddress) => {                
                return {
                    url: `/api/finapibank/bankupdate/?ip=${userIPAddress}`,
                    method: "GET",
                }
            },
        }),
        checkUpdateBankData: builder.query({            
            query: (webformUpdateId) => {                
                return {
                    url: `/api/finapibank/check-bankupdate/?id=${webformUpdateId}`,
                    method: "GET",
                }
            },
        }),
        hideBankData: builder.mutation({
            query: ({ bankId, hideState }) => { // Parameter als Objekt
                console.log('Mutation', bankId, hideState);
                return {
                    url: `/api/finapibank/update-hide/?id=${bankId}&hide=${hideState}`,
                    method: "PATCH",
                }
            },
        }),
    }),
});

export const { useConnectionsMutation, useLazyGetAllConnectionQuery, useDeleteConnectionsMutation, useLazyCheckAuthQuery, useLazyAddTokenQuery, useLazyConnectionStatusQuery, useCompletedConnectionMutation, useLazyUpdateBankDataQuery, useLazyCheckUpdateBankDataQuery, useHideBankDataMutation } = connectionApiSlice;
