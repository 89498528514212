import { apiSlice } from "../api.slice";

export const invoiceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    invoices: builder.query({
      query: () => ({
        url: "/api/sevdesk/invoices/",
        method: "GET",
      }),
    }),
    getInvoice: builder.query({
      query: (transId) => ({
        url: `/api/sevdesk/invoices/${transId ? `?transaction_id=${transId}` : ""
          }`,
        method: "GET",
      }),
    }),
    getInvoicesPDF: builder.query({
      query: (id) => ({
        url: `/api/sevdesk/invoice/${id}/getPdf`,
        method: "GET",
      }),
    }),
    invoicesUpdate: builder.mutation({
      query: (payload) => ({
        url: "/api/sevdesk/invoices/",
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    invoiceRevert: builder.mutation({
      query: (payload) => ({
        url: "/api/sevdesk/invoice-revert/",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
  }),
});

export const {
  // useInvoicesQuery,
  useLazyInvoicesQuery,
  useLazyGetInvoiceQuery,
  useLazyGetInvoicesPDFQuery,
  useInvoicesUpdateMutation,
  useInvoiceRevertMutation,
} = invoiceApiSlice;
