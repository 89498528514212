import * as React from "react";
import { styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import styles from './layout.module.scss'
import Header from "_components/header/Header";
import Sidebar from "_components/sidebar/Sidebar";

//Styles
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Layout = ({ children }) => {

  //States
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box className={styles.Dashboard} sx={{ display: "flex" }}>
        <CssBaseline />

        <Header
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />

        <Sidebar
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />

        <Box component="main" className={styles.main_wrp}>
          <DrawerHeader />
          {children}
        </Box>
      </Box>
    </>
  );
};

export default Layout;
