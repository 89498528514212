import {
    Box,
    Button,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
} from "@mui/material";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
    useInvoiceRevertMutation,
    useLazyGetInvoicesPDFQuery,
    useLazyInvoicesQuery,
} from "_store/invoice/invoiceApi.slice";
import { useEffect } from "react";
import { setInvoices } from "_store/invoice/invoice.slice";
import { useDispatch, useSelector } from "react-redux";
import styles from "./datatable.module.scss";
import { useTranslation } from "react-i18next";
import DownloadForOfflineSharpIcon from "@mui/icons-material/DownloadForOfflineSharp";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "_components/modals/ConfirmModal";
import LinearProgress from '@mui/material/LinearProgress';

const InvoiceDataTable = ({ tag, clearFilteres }) => {
    const navigate = useNavigate();
    const [getInvoicePDFAPICall] = useLazyGetInvoicesPDFQuery();
    //state for pdf
    const [isEmbed, setIsEmbed] = React.useState(false);
    const [pdfURL, setPdfURL] = React.useState("");
    //States

    const dispatch = useDispatch();
    const invoiceData = useSelector((state) => state.invoice);
    const [invoiceRevert] = useInvoiceRevertMutation();
    const [confirmModal, setConfirmModal] = React.useState(false);
    const [sevdesId, setSevdesId] = React.useState("");

    const [getInvoices, { isFetching: isInvoiceLoading }] = useLazyInvoicesQuery();

    const [rows, setRows] = React.useState([]);
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [filters, setFilters] = React.useState({
        serviceType: "",
        startDate: null,
        endDate: null,
        searchQuery: "",
        tag,
    });
    const { t } = useTranslation();
    const handleclose = () => {
        setConfirmModal(false)
    }
    const handleSuccessConfirmModal = () => {
        let payload = {
            invoice_id: sevdesId,
        };
        invoiceRevert(payload)
            .then((res) => {
                if (res.data !== undefined) {
                    getInvoices().then((res) => {
                        setRows(parseData(res?.data?.results))
                        dispatch(setInvoices(parseData(res?.data?.results)))
                    })
                    // refetch();
                }
                setConfirmModal(false)
            })
            .catch((err) => {
                setConfirmModal(false)
            });
    }


    const handleRevert = (id) => {
        setConfirmModal(true)
        setSevdesId(id)
    };
    //invoice download
    const InvoiceDownload = async (id) => {
        await getInvoicePDFAPICall(id)
            .unwrap()
            .then((res) => {
                if (res?.objects?.content) {
                    setIsEmbed(true);
                    setPdfURL(`data:application/pdf;base64,${res?.objects?.content}`);
                }
            })
            .catch((err) => console.log(err));
    };
    //Rows and Cols
    let columns = [
        {
            field: "client",
            headerName: `${t("invoiceClient")}`,
            flex: 2.7,
            sortable: false,
            renderCell: ({ row }) => {
                return (
                    <>
                        <Box className={styles.table_client_wrp}>
                            <Box className={styles.client_box}>
                                {/* <img src={logo} alt={logo}/> */}L
                            </Box>
                            <Box className={styles.details}>
                                <Typography variant="h6">{row?.client}</Typography>
                                <Typography>{row.invoiceId}</Typography>
                            </Box>
                        </Box>
                    </>
                );
            },
        },
        {
            field: "create",
            headerName: `${t("date")}`,
            flex: 1,
        },
        {
            field: "amount",
            headerName: `${t("invoiceAmount")}`,
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <>
                        <Typography>
                            {tag === "part-paid" ? row.unpaid_amount?.toFixed(2) : row?.amount}
                        </Typography>
                    </>
                );
            },
        },
        {
            field: "currency",
            headerName: `${t("currency")}`,
            sortable: false,
            flex: 1,
        },
        {
            field: "type",
            headerName: `${t("invoiceType")}`,
            sortable: false,
            flex: 0.8,
        },
        {
            field: "status",
            headerName: "Status",
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                let tag = "",
                    status = row.status;
                if (status === "Deactivated recurring invoice") {
                    tag = "red_tag";
                }
                if (status === "Payed") {
                    tag = "green_tag";
                }
                if (status === "Draft") {
                    tag = "black_tag";
                }
                if (status === "Open / Due") {
                    tag = "yello_tag";
                }
                return (
                    <>
                        <Box>
                            <Typography className={tag}>{status}</Typography>
                        </Box>
                    </>
                );
            },
        },
        {
            field: "DOWNLOAD",
            headerName: `${t("Details")} (pdf)`,
            flex: 1,
            align: "center",

            renderCell: ({ row }) => {
                return (
                    <>
                        <Box sx={{ textAlign: "center", cursor: "pointer" }}>
                            <DownloadForOfflineSharpIcon
                                onClick={() => {
                                    InvoiceDownload(row.sevdesk_id);
                                }}
                            />
                        </Box>
                    </>
                );
            },
        },
        {
            field: "Connected Transactions",
            headerName: `${t("connectedTransactions")}`,
            flex: 1.4,
            align: "left",
            renderCell: ({ row }) => {
                return <>{row.connected_transaction ?? "-"}</>;
            },
        },
        {
            field: "ACTION",
            headerName: `${t("Action")}`,
            flex: 1.4,
            // align: "center",
            renderCell: ({ row }) => {
                return (
                    <>
                        {row.connected_transaction ? (
                            <Button
                                onClick={() => {
                                    handleRevert(row.sevdesk_id);
                                }}
                            >
                                {t('Revert')}
                            </Button>
                        ) : (
                            "-"
                        )}
                    </>
                );
            },
        },
    ];

    //Change handlers
    const handleChangeServiseType = (event) => {
        setFilters((old) => ({ ...old, serviceType: event?.target?.value ?? "" }));
    };

    const handleChangeStartDate = (startDate) => {
        setStartDate(startDate);
        setFilters((old) => ({
            ...old,
            startDate: startDate.format("YYYY-MM-DD"),
        }));
    };

    const handleChangeEndDate = (endDate) => {
        setEndDate(endDate);
        setFilters((old) => ({ ...old, endDate: endDate.format("YYYY-MM-DD") }));
    };

    const handleChangeSearchQuery = (event) => {
        setFilters((old) => ({ ...old, searchQuery: event.target.value }));
    };

    //Filteration
    const clearAllFilteres = () => {
        setFilters({
            serviceType: "",
            startDate: null,
            endDate: null,
            searchQuery: "",
            tag: "all",
        });
        clearFilteres();
        setRows(invoiceData.invoices);
    };

    const parseData = (data) => {
        let tmpData = data?.map((item) => {
            return {
                id: item.id,
                invoiceId: item.invoice_number,
                client: item.contact_company_name,
                create: item.invoice_date?.split("T")[0],
                amount:
                    item.currency === "EUR"
                        ? item.amount_in_euros?.toFixed(2)
                        : item.amount_in_foreign_currency?.toFixed(2),
                currency: item.currency,
                status: item.status,
                type: item.invoice_type,
                sevdesk_id: item.sevdesk_id,
                connected_transaction: item.connected_transactions,
                amt: item.amount_in_euros,
                unpaid_amount: item.unpaid_amount,
            };
        });
        return tmpData;
    };


    //Effects
    useEffect(() => {
        getInvoices().then((res) => {
            setRows(parseData(res?.data?.results))
            dispatch(setInvoices(parseData(res?.data?.results)))
        }
        )
    }, [dispatch, getInvoices]);

    useEffect(() => {
        let tmpFilter = { ...filters };

        if (tag === "reversal") {
            tmpFilter = { ...tmpFilter, serviceType: "SR", tag: "" };
        }

        let data = invoiceData.invoices;

        //Service filteration
        let filteredData = data?.filter((item) => {
            if (tmpFilter.serviceType === "") return true;
            else return item.type === tmpFilter.serviceType;
        });

        //Date filteration
        filteredData = filteredData?.filter((item) => {
            if (
                item?.create === "" ||
                tmpFilter.startDate === null ||
                tmpFilter.endDate === null
            )
                return true;
            else {
                let create = new Date(item?.create);
                let start = new Date(tmpFilter.startDate);
                let end = new Date(tmpFilter.endDate);
                return create > start && create < end;
            }
        });

        //Search query
        filteredData = filteredData?.filter((item) => {
            if (item?.contact_company_name === "" || tmpFilter.searchQuery === "")
                return true;
            else {
                let client = item?.client?.toLowerCase();
                let tamt = item?.amount?.toString();
                let tinvoiceId = item?.invoiceId?.toLowerCase();
                return (
                    client?.includes(tmpFilter.searchQuery?.toLowerCase()) ||
                    tamt?.includes(tmpFilter.searchQuery) ||
                    tinvoiceId?.includes(tmpFilter.searchQuery?.toLowerCase())
                );
            }
        });

        //Tag filteration
        filteredData = filteredData?.filter((item) => {
            if (tmpFilter.tag === "all" || tmpFilter.tag === "") return true;
            else if (tmpFilter.tag === "Open / Due")
                return item.status == "Open / Due" && item.amount == item.unpaid_amount
            else if (tmpFilter.tag == "part-paid" && item.unpaid_amount > 0 && item.status == "Open / Due")
                return item.unpaid_amount != item.amount
            else return item?.status === tmpFilter.tag;
        });

        setRows(filteredData);
        setInvoices(filteredData);
    }, [filters, invoiceData, tag]);
    useEffect(() => {
        setFilters((old) => ({ ...old, tag: tag }));
    }, [tag]);

    return (
        <>
            {isEmbed &&
                pdfURL &&
                navigate("/download-pdf", {
                    state: { Url: pdfURL, Show: isEmbed, Path: "invoice" },
                })}
            {!isEmbed && !pdfURL && (
                <>
                    <ConfirmModal
                        open={confirmModal}
                        handlesuccess={handleSuccessConfirmModal}
                        handleclose={handleclose}
                        message="Are you want to revert this change?"
                        header=""
                    />
                    <Box className={styles.table_filter}>
                        <Grid className={styles.top_bar} container spacing={2}>
                            <Grid item md={2} xs={12}>
                                <FormControl fullWidth className={styles.form_box}>
                                    <InputLabel id="demo-simple-select-label">
                                        {t("invoiceServiceType")}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={filters.serviceType}
                                        onChange={(event) => handleChangeServiseType(event)}
                                        label="Service type"
                                    >
                                        <MenuItem value="RE">RE</MenuItem>
                                        <MenuItem value="SR">SR</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth className="form_box">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={["DatePicker"]}>
                                            <DatePicker
                                                label={t("invoiceStartDate")}
                                                slotProps={{
                                                    textField: { fullWidth: true, error: false },
                                                }}
                                                value={startDate}
                                                onChange={(startDate) =>
                                                    handleChangeStartDate(startDate)
                                                }
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth className="form_box">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer fullWidth components={["DatePicker"]}>
                                            <DatePicker
                                                label={t("invoiceEndDate")}
                                                slotProps={{
                                                    textField: { fullWidth: true, error: false },
                                                }}
                                                value={endDate}
                                                onChange={(endDate) => handleChangeEndDate(endDate)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Box className={styles.search_box}>
                                    <Box>
                                        <FormControl className="form_box">
                                            <OutlinedInput
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                }
                                                placeholder={t("invoiceSearch")}
                                                value={filters.searchQuery}
                                                onChange={(event) => {
                                                    handleChangeSearchQuery(event);
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                    {filters.endDate ||
                                        filters.startDate ||
                                        filters.searchQuery ||
                                        filters.serviceType ||
                                        tag !== "all" ? (
                                        <Box className={styles.clear_filter}>
                                            <Button
                                                size="medium"
                                                color="error"
                                                onClick={clearAllFilteres}
                                            >
                                                <DeleteOutlineOutlinedIcon />
                                                {t("invoiceClear")}
                                            </Button>
                                        </Box>
                                    ) : (
                                        <></>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className="data_table_wrp">
                        <DataGrid
                            className="DataTable"
                            disableColumnMenu
                            autoHeight
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 50,
                                    },
                                },
                            }}                            
                            pageSizeOptions={[50, 100, 500]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            density="comfortable"
                            disableDensitySelector={false}
                            densityStandardIcon
                            slots={{
                                loadingOverlay: LinearProgress,
                              }}
                              loading={isInvoiceLoading}
                        />
                    </Box>
                </>
            )}
        </>
    );
};

export default InvoiceDataTable;
