import { createSlice } from "@reduxjs/toolkit";

// create slice
const name = "auth";
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

// exports
export const invoiceActions = { ...slice.actions };
export const invoiceReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        invoices: [],
        error: null,
    };
}

function createReducers() {

    const setInvoices = (state, action) => {
        state.invoices = action.payload || []
    };

    const clrInvoices = (state, action) => {
        state.invoices = []
    };

    return {
        setInvoices,
        clrInvoices
    };
}

export const { setInvoices, clrInvoices } = slice.actions;
