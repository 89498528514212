import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, ListItemIcon, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from "@mui/material/styles";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import styles from "./Password.module.scss";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useResetPasswordMutation } from '_store/auth/authApi.slice';
import { yupResolver } from '@hookform/resolvers/yup';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Alert from '@mui/material/Alert';
import Flag from 'react-world-flags'
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';




const RedditTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
    "& .MuiFilledInput-root": {
        border: "1px solid #e2e2e1",
        overflow: "hidden",
        borderRadius: 4,
        backgroundColor:
            theme.palette.mode === "transparent" ? "transparent" : "transparent",
        transition:
            "border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        // borderRadius: "8px",
        // transition: theme.transitions.create([
        //     "border-color",
        //     "background-color",
        //     "box-shadow",
        // ]),
        "&:hover": {
            backgroundColor: "transparent",
        },
        "&.Mui-focused": {
            backgroundColor: "transparent",
            boxShadow: "0px 0px 0px 2px rgb(67, 56, 202)",
            borderColor: "rgb(67, 56, 202)",
        },
    },
}));

function ResetPassword() {

    //Consts
    const initialPasswordValidationError = {
        minChar: false,
        minCap: false,
        minSmall: false,
        minSpecial: false,
        minNumber: false,
    }

    //States
    const navigate = useNavigate()
    const { token } = useParams()
    const [passwordValidation, setPasswordValidation] = useState(initialPasswordValidationError)
    const [isTouched, setIsTouched] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const [randomPassword, setRandomPassword] = useState('')

    const { t, i18n } = useTranslation();

    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
    }

    //API - Forgot password mutation
    const [resetPassword, { isSuccess, data }] = useResetPasswordMutation()

    //Yup validation
    const validationSchema = Yup.object().shape({
        password: Yup.string().required("Email is required"),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    function generateRandomPassword() {
        const capitalLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const smallLetters = 'abcdefghijklmnopqrstuvwxyz';
        const specialCharacters = '!@#$%^&*()_+~`|}{[]\\:;?><,./-=';
        const numbers = '0123456789';

        const getRandomChar = (characters) => {
            return characters[Math.floor(Math.random() * characters.length)];
        };

        let password = '';

        // Generate minimum 3 random capital letters
        for (let i = 0; i < 3; i++) {
            password += getRandomChar(capitalLetters);
        }

        // Generate minimum 3 random small letters
        for (let i = 0; i < 3; i++) {
            password += getRandomChar(smallLetters);
        }

        // Generate minimum 3 random special characters
        for (let i = 0; i < 3; i++) {
            password += getRandomChar(specialCharacters);
        }

        // Generate minimum 3 random numbers
        for (let i = 0; i < 3; i++) {
            password += getRandomChar(numbers);
        }

        // Generate additional random characters to reach length 16
        const remainingLength = 16 - password.length;
        for (let i = 0; i < remainingLength; i++) {
            // Combine all characters to choose from
            const allCharacters = capitalLetters + smallLetters + specialCharacters + numbers;
            password += getRandomChar(allCharacters);
        }

        return password;
    }

    useEffect(() => {
        setIsTouched(true)
        const randomPassowrd = generateRandomPassword()
        setRandomPassword(randomPassowrd)
    }, [])

    //Formik
    const { register, handleSubmit, formState: { errors } } = useForm(formOptions);

    //Submit functionality
    async function onSubmit({ password }) {
        let isValid = true
        Object.keys(passwordValidation).forEach((key) => {
            if (passwordValidation[key] === false)
                isValid = false
        })
        if (isValid) {
            // const encryptedPass = bcrypt.hashSync(password, salt)
            resetPassword({ password, token })
        }
    }
    const handleCopy = async () => {
        await navigator.clipboard.writeText(randomPassword);
    }

    const handleReset = async () => {
        const randomPassowrd = generateRandomPassword()
        setRandomPassword(randomPassowrd)
    }

    //Password validation
    const validatePassword = (value) => {

        let errorData = { ...passwordValidation }
        if (value.length < 16) {
            errorData = { ...errorData, minChar: false }
        } else {
            errorData = { ...errorData, minChar: true }
        }

        let numUpper = value.length - value.replace(/[A-Z]/g, '').length;
        if (numUpper < 3) {
            errorData = { ...errorData, minCap: false }
        } else {
            errorData = { ...errorData, minCap: true }
        }

        let numLower = value.length - value.replace(/[a-z]/g, '').length;
        if (numLower < 3) {
            errorData = { ...errorData, minSmall: false }
        } else {
            errorData = { ...errorData, minSmall: true }
        }

        const regex = /[!@#$%^&*()_+\-=\\[\]{}<>;:.,'"\\|\\/?]/g;
        const result = value.match(regex);
        if (!result) {
            errorData = { ...errorData, minSpecial: false }
        } else {
            if (result.length < 3) {
                errorData = { ...errorData, minSpecial: false }
            } else {
                errorData = { ...errorData, minSpecial: true }
            }
        }

        let numNumber = value.length - value.replace(/[0-9]/g, '').length;
        if (numNumber < 3) {
            errorData = { ...errorData, minNumber: false }
        } else {
            errorData = { ...errorData, minNumber: true }
        }

        setPasswordValidation(errorData)
    }

    //Effect
    useEffect(() => {
        if (isSuccess) {
            setShowAlert(true)
            setTimeout(() => {
                navigate('/')
            }, 5000)

        }
    }, [isSuccess, navigate])

    useEffect(() => {
        if (showAlert) {
            // Hide the alert after 5 seconds
            const timeout = setTimeout(() => {
                setShowAlert(false);
            }, 5000);

            // Clean up the timeout when the component unmounts
            return () => clearTimeout(timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <FormControl className={`${styles.select_language} form_box`}>
                        <InputLabel id="demo-simple-select-label">Language</InputLabel>
                        <Select sx={{ maxWidth: "200px" }}
                            value={i18n.language}
                            label="Language"
                            onChange={(event) => handleLanguageChange(event.target.value)}
                        >
                            <MenuItem value={"en"}>
                                <ListItemIcon>
                                    <Flag code={'gb'} />
                                </ListItemIcon>
                            </MenuItem>
                            <MenuItem value={"de"}>
                                <ListItemIcon>
                                    <Flag code={'de'} />
                                </ListItemIcon>
                            </MenuItem>
                            {/* <MenuItem value={"de"}>German</MenuItem> */}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center">
                <Grid item xs={8}>
                    <Box className={styles.auth_box}>
                        <Box className={styles.auth_data_box}>
                            <Box className={styles.auth_title_top}>
                                <Typography className={styles.auth_title} variant="h5">
                                    {t('resetpassword')}
                                </Typography>
                            </Box>
                            <Box className={styles.auth_data_body}>
                                <Box className={styles.suggestion_text}>
                                    <p className={styles.suggestion_text_p}>{t('SuggestionText')}</p>
                                </Box>
                                <Box className={styles.auto_generate}>
                                    <RedditTextField
                                        fullWidth
                                        label={t('GenerateRandomPassowrd')}
                                        type="text"
                                        value={randomPassword}
                                        variant="filled" />
                                    <div className={styles.icons}>
                                        <Tooltip title={t('copy')}>
                                            <CopyAllIcon fontSize="small" onClick={handleCopy} />
                                        </Tooltip>
                                        <Tooltip title={t('Refresh')}>
                                            <AutorenewIcon fontSize="small" onClick={handleReset} />
                                        </Tooltip>
                                    </div>
                                </Box>
                                <form
                                    className={styles.auth_form}
                                    noValidate
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <Box className={styles.formField_box}>
                                        <RedditTextField
                                            fullWidth
                                            name="password"
                                            type={showPassword ? 'text' : 'password'}
                                            label={t('password')}
                                            variant="filled"
                                            inputProps={{
                                                ...register("password", {
                                                    onChange: (e) => {
                                                        validatePassword(e.target?.value)
                                                    },
                                                })
                                            }}
                                            InputProps={{
                                                endAdornment: showPassword ? (
                                                    <RemoveRedEyeIcon
                                                        fontSize="small"
                                                        onClick={handlePasswordVisibility}
                                                    />
                                                ) : (
                                                    <VisibilityOffIcon
                                                        fontSize="small"
                                                        onClick={handlePasswordVisibility}
                                                    />
                                                ),
                                            }} />
                                        {/* {showPassword ? <RemoveRedEyeIcon fontSize="small" onClick={handleTogglePasswordVisibility} />
                                            : <VisibilityOffIcon fontSize="small" onClick={handleTogglePasswordVisibility} />} */}

                                    </Box>
                                    <Box className={styles.formField_box}>
                                        <RedditTextField
                                            fullWidth
                                            name="confirmPassword"
                                            label={t('ConfirmPassword')}
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            variant="filled"
                                            inputProps={{ ...register("confirmPassword") }}
                                            InputProps={{
                                                endAdornment: showConfirmPassword ? (
                                                    <RemoveRedEyeIcon
                                                        fontSize="small"
                                                        onClick={handleConfirmPasswordVisibility}
                                                    />
                                                ) : (
                                                    <VisibilityOffIcon
                                                        fontSize="small"
                                                        onClick={handleConfirmPasswordVisibility}
                                                    />
                                                ),
                                            }} />
                                        {errors?.confirmPassword?.message && (
                                            <FormHelperText>
                                                {errors?.confirmPassword?.message}
                                            </FormHelperText>
                                        )}
                                    </Box>

                                    {isTouched && <Box className={styles.password_conditions_box}>
                                        <Typography className={styles.password_conditions}>
                                            {passwordValidation.minChar ? <CheckIcon color='success' fontSize='small' /> : <CloseIcon color='error' fontSize='small' />}
                                            min 16 char
                                        </Typography>
                                        <Typography className={styles.password_conditions}>
                                            {passwordValidation.minCap ? <CheckIcon color='success' fontSize='small' /> : <CloseIcon color='error' fontSize='small' />}
                                            min 3 Capital letter
                                        </Typography>
                                        <Typography className={styles.password_conditions}>
                                            {passwordValidation.minSmall ? <CheckIcon color='success' fontSize='small' /> : <CloseIcon color='error' fontSize='small' />}
                                            min 3 small letter
                                        </Typography>
                                        <Typography className={styles.password_conditions}>
                                            {passwordValidation.minSpecial ? <CheckIcon color='success' fontSize='small' /> : <CloseIcon color='error' fontSize='small' />}
                                            min 3 special Character
                                        </Typography>
                                        <Typography className={styles.password_conditions}>
                                            {passwordValidation.minNumber ? <CheckIcon color='success' fontSize='small' /> : <CloseIcon color='error' fontSize='small' />}
                                            min 3 numbers
                                        </Typography>
                                    </Box>}

                                    <Button type="submit" className={styles.auth_button}>
                                        {t('resetpassword')}
                                    </Button>
                                </form>

                                {showAlert && (
                                    <Alert className={styles.login_popup} severity="success" onClose={() => setShowAlert(false)}>
                                        Password Reset Successfully !!
                                    </Alert>
                                )}

                                {isSuccess && (
                                    <Box sx={{ color: "green" }}>
                                        <span className={styles.success_message}><CheckIcon color='success' fontSize='small' />{data?.message}</span>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default ResetPassword