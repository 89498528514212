import React from 'react'
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, ListItemIcon, MenuItem, Select, TextField, Typography } from '@mui/material'
import { styled } from "@mui/material/styles";
import styles from "./Password.module.scss";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForgotPasswordMutation } from '_store/auth/authApi.slice';
import CheckIcon from '@mui/icons-material/Check';
import Flag from 'react-world-flags'
import { useTranslation } from 'react-i18next';


const RedditTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
    "& .MuiFilledInput-root": {
        border: "1px solid #e2e2e1",
        overflow: "hidden",
        borderRadius: 4,
        backgroundColor:
            theme.palette.mode === "transparent" ? "transparent" : "transparent",
        transition:
            "border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        // borderRadius: "8px",
        // transition: theme.transitions.create([
        //     "border-color",
        //     "background-color",
        //     "box-shadow",
        // ]),
        "&:hover": {
            backgroundColor: "transparent",
        },
        "&.Mui-focused": {
            backgroundColor: "transparent",
            boxShadow: "0px 0px 0px 2px rgb(67, 56, 202)",
            borderColor: "rgb(67, 56, 202)",
        },
    },
}));

function ForgotPassword() {

    //API - Forgot password mutation
    const [forgotPassword, { error, isError, isSuccess, data }] = useForgotPasswordMutation()

    //Yup validation
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid Email').required("Email is required"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    //Formik
    const { register, handleSubmit, formState: { errors } } = useForm(formOptions);

    //Submit functionality
    async function onSubmit({ email }) {
        forgotPassword({ email })
    }
    const { t, i18n } = useTranslation();

    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
    }


    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <FormControl className={`${styles.select_language} form_box`}>
                        <InputLabel id="demo-simple-select-label">Language</InputLabel>
                        <Select sx={{ maxWidth: "200px" }}
                            value={i18n.language}
                            label="Language"
                            onChange={(event) => handleLanguageChange(event.target.value)}
                        >
                            <MenuItem value={"en"}>
                                <ListItemIcon>
                                    <Flag code={'gb'} />
                                </ListItemIcon>
                            </MenuItem>
                            <MenuItem value={"de"}>
                                <ListItemIcon>
                                    <Flag code={'de'} />
                                </ListItemIcon>
                            </MenuItem>
                            {/* <MenuItem value={"de"}>German</MenuItem> */}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center">
                <Grid item xs={8}>
                    <Box className={styles.auth_box}>
                        <Box className={styles.auth_data_box}>
                            <Box className={styles.auth_title_top}>
                                <Typography className={styles.auth_title} variant="h5">
                                    {t('forgetpassword')}
                                </Typography>
                            </Box>
                            <Box className={styles.auth_data_body}>
                                <form
                                    className={styles.auth_form}
                                    noValidate
                                    onSubmit={handleSubmit(onSubmit)}>
                                    <RedditTextField
                                        fullWidth
                                        name="email"
                                        label={t('enterEmailAddress')}
                                        variant="filled"
                                        inputProps={{ ...register("email") }} />
                                    {errors?.email?.message && (
                                        <FormHelperText>
                                            {errors?.email?.message}
                                        </FormHelperText>
                                    )}
                                    <Button type="submit" className={styles.auth_button}>
                                    {t('resetpassword')}
                                    </Button>
                                </form>
                                {isError && (
                                    <Box sx={{ color: "red" }}>
                                        {error?.data?.detail}
                                    </Box>
                                )}

                                {isSuccess && (
                                    <Box sx={{ color: "green" }}>
                                        <span className={styles.success_message}><CheckIcon color='success' fontSize='small' />{data?.message}</span>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default ForgotPassword