import { createSlice } from "@reduxjs/toolkit";

// create slice
const name = "auth";
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

// exports
export const creditNotesActions = { ...slice.actions };
export const creditNotesReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        creditNotes: [],
        error: null,
    };
}

function createReducers() {

    const setCreditNotes = (state, action) => {
        state.creditNotes = action.payload || []
    };

    const clrCreditNotes = (state, action) => {
        state.creditNotes = []
    };

    return {
        setCreditNotes,
        clrCreditNotes
    };
}

export const { setCreditNotes, clrCreditNotes } = slice.actions;
