import { Button, Box } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

function InvoicePDF() {
    const navigate = useNavigate()
    const location = useLocation()

    const [displayPDF, setDisplayPDF] = useState(false)
    const [pdfURL, setPdffURL] = useState(false)
    const [path, setPath] = useState("")
    const handlePDFClose = () => {
        setDisplayPDF(false)
        setPdffURL("")
    }
    useEffect(() => {
        if (location.state && location?.state?.Url && location?.state?.Show && location?.state?.Path) {
            const { Url, Show, Path } = location?.state
            setDisplayPDF(Show)
            setPdffURL(Url)
            setPath(Path)
        }
    }, [location?.state])
    return (
        <>
            {displayPDF && pdfURL ? <div> <>
                <Box sx={{ my: 2, textAlign: "right" }} >
                    <Button variant="contained" onClick={() => { handlePDFClose() }}>Close</Button>
                </Box>
                <embed src={pdfURL} type="application/pdf" width="100%" height="1000px" />
            </></div > : path && path === "invoice" ? navigate("/invoice") : navigate("/credit-notes")
            }
        </>
    )
}

export default InvoicePDF