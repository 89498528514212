import { history } from "_helpers";
import { apiSlice } from "../api.slice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login/",
        method: "POST",
        body: { ...credentials },
        responseHandler: (response) => {
          const responseStatus = response?.status;
          if (responseStatus === 404) history.navigate("/404");
          else if (responseStatus === 500) history.navigate("/500");
          return response.text();
        },
      }),
    }),

    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "/auth/forgot_password/",
        method: "POST",
        body: data,
      }),
    }),

    resetPassword: builder.mutation({
      query: (data) => {
        return{
        url: `/auth/reset_password/${data.token}/`,
        method: "POST",
        body: {password:data.password},
      }},
    }),

  }),
});

export const { useLoginMutation, useForgotPasswordMutation, useResetPasswordMutation } = authApiSlice;
