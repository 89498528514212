import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { history } from "_helpers";
import Layout from "../_components/layout/Layout";

export { PrivateRoute };

function PrivateRoute({ children }) {
  const authUser = useSelector((x) => x.auth);
  const { isLogin } = authUser.user || {};
  if (!Boolean(isLogin)) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/" state={{ from: history.location }} />;
  }

  // authorized so return child components
  return <Layout>{children}</Layout>;
}
