/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  TablePagination,
  OutlinedInput,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  TableFooter,
  TextField,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { AccordionDetails, AccordionSummary, Accordion } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import {
  useConnectionsMutation,
  useDeleteConnectionsMutation,
  useLazyAddTokenQuery,
  useLazyCheckAuthQuery,
  useLazyGetAllConnectionQuery,
  useLazyConnectionStatusQuery,
  useCompletedConnectionMutation,
} from "_store/connection/connectionApi.slice";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import styles from "./BankConnection.module.scss";
import { useTranslation } from "react-i18next";

function FinApiWebForm() {
  const formContainerRef = useRef(null);
  const { t } = useTranslation();
  const [deleteId, setDeleteId] = useState("");

  //API ######
  const [
    getConnections,
    {
      data: connectionsData,
      isLoading: isConnectionsLoading,
      error: errorConnections,
    },
  ] = useConnectionsMutation();
  const [
    completedConnection,
    // {
    //   data: completedConnectionData,
    //   isLoading: isCompletedConnectionLoading,
    //   error: errorCompletedConnections,
    // },
  ] = useCompletedConnectionMutation();

  const { error } = errorConnections?.data || {};
  const [checkAuthData] = useLazyCheckAuthQuery();
  const [addToken] = useLazyAddTokenQuery();
  const [checkConnectionStatus] = useLazyConnectionStatusQuery();
  const [connectionData, setConnectionData] = useState();
  const [showQuery, setShowQuery] = useState(false);
  const [customErr, setCustomErr] = useState({ errorMsg: "", status: false });

  const [getAllConnection, { isFetching: isAllConnectionFetching }] =
    useLazyGetAllConnectionQuery();
  const [
    deleteConnection,
    { isSuccess: isDeleteSuccess, isLoading: isDeleteLoading },
  ] = useDeleteConnectionsMutation();
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [loader, setLoader] = React.useState(false);

  //set reducer
  useEffect(() => {
    getAllConnection()
      .unwrap()
      .then((res) => {
        if (res) {
          setRows(res);
        }
      })
      .catch((err) => {
        setRows([]);
      });
  }, []);

  useEffect(() => {
    if (isDeleteSuccess) {
      setRows(rows?.filter((item) => item?.webform_id !== deleteId));
    }
  }, [isDeleteSuccess, deleteId]);
  const handleChange = (event) => {
    if (event.target.value?.length > 0) {
      event.target.value?.match(/^[0-9]+$/)
        ? setCustomErr({ errorMsg: "", status: false })
        : setCustomErr({ errorMsg: t("BLZCODEERR"), status: true });
    } else {
      setCustomErr({ errorMsg: "", status: false });
    }
  };
  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      if (customErr?.status !== true && !isAllConnectionFetching) {
        await getConnections(event.target.value)
          .unwrap()
          .then((res) => {
            if (res) {

              if (rows.length > 0) {
                rows?.map((item) =>
                  item.webform_id !== res.webform_id
                    ? (setRows([res, ...rows]),
                      setConnectionData(res),
                      setLoader(true))
                    : ""
                );
              }
              else {
                setConnectionData(res)
                setLoader(true)
                setRows([res])
              }
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  useEffect(() => {
    if (isDeleteSuccess && !isDeleteLoading && deleteId.trim() !== "") {
      setDeleteId("");
    }
  }, [rows, deleteId, isDeleteLoading, isDeleteSuccess]);

  const CustomSkeleton = () =>
    Array.from({ length: 5 }).map((_, key) => (
      <TableRow key={key}>
        <TableCell>
          <Skeleton variant="rect" width="100%" height={50} />
        </TableCell>
        <TableCell>
          <Skeleton variant="rect" width="100%" height={50} />
        </TableCell>
        <TableCell>
          <Skeleton variant="rect" width="100%" height={50} />
        </TableCell>
        <TableCell>
          <Skeleton variant="rect" width="100%" height={50} />
        </TableCell>
      </TableRow>
    ));

  const CustomNoRowsAvailable = () => (
    <TableRow>
      <TableCell style={{ textAlign: "center" }} colSpan={4}>
        {t("noRow")}
      </TableCell>
    </TableRow>
  );
  const [AuthError, setAuthError] = useState({
    error: false,
    errorMsg: "",
  });
  const [tokens, setTokens] = useState({
    basic_token: "",
  });

  const handleBasicTokenChange = (event) => {
    setTokens({ ...tokens, basic_token: event.target.value });
  };

  const checkAuth = async () => {
    try {
      await checkAuthData().unwrap();
      setAuthError({
        error: false,
        errorMsg: "",
      });
    } catch (err) {
      setAuthError({
        error: true,
        errorMsg: err?.data?.error,
      });
    }
  };
  const addAuthToken = () => {
    addToken({ basic: tokens.basic_token });
  };

  let apiCallCount = 1;
  let flag = false;

  const makeApiCall = async () => {
    try {
      await checkConnectionStatus(connectionData.webform_id)
        .unwrap()
        .then((res) => {
          setRows(
            rows?.map((item) =>
              item.webform_id === res.webform_id ? res : item
            )
          );
          if (
            res.connection_status !== undefined &&
            res.connection_status !== connectionData.connection_status
          ) {
            if (res.connection_status === "IN_PROGRESS") {
              setLoader(true);
              flag = false;
            } else {
              if (res.connection_status === "COMPLETED") {
                completedConnection(connectionData.webform_id);
              }
              setLoader(false);
              flag = true;
              // rows.shift();
              // setRows([res, ...rows]);
              return;
            }
          } else {
            flag = false;
            setLoader(true);
          }
        });
      apiCallCount++;
      //Call timer count limit
      if (apiCallCount <= 60 && !flag) {
        setTimeout(makeApiCall, 10000);
      } else {
        setLoader(false);
      }
    } catch (error) {
      flag = true;
      setLoader(false);
      return;
    }
  };

  const handleStatus = () => {
    makeApiCall();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={styles.renderPage}>
            <Typography variant="h5">{t("ConnectYourFinance")}</Typography>
            <div ref={formContainerRef}></div>
            <Box className={styles.render}>
              <Grid container spacing={2}>
                <Grid item lg={12} sm={12} md={12} xs={12}>
                  <Accordion
                    className={styles.accordionBox}
                    onClick={setShowQuery}
                  >
                    <AccordionSummary
                      expandIcon={<AddCircleOutlineIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{t("ConnectYourBank")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className={styles.search_box}>
                        {showQuery ? (
                          <Box>
                            <FormControl className={styles.form_box} fullWidth>
                              <OutlinedInput
                                startAdornment={
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                }
                                placeholder={t("BLZCodePlaceholder")}
                                inputProps={{ maxLength: 8 }}
                                onKeyPress={handleKeyPress}
                                onChange={handleChange}
                              />
                            </FormControl>
                            {customErr.status === true && (
                              <Typography sx={{ color: "red" }}>
                                {customErr?.errorMsg}
                              </Typography>
                            )}
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>

                      {connectionsData ? (
                        <Box className={styles.plusIcon}>
                          <Typography variant="contained">
                            {connectionsData?.bank_name}
                          </Typography>
                          <Button
                            variant="contained"
                            href={connectionsData?.url}
                            onClick={() => {
                              handleStatus();
                            }}
                            target="_blank"
                            underline="hover"
                          >
                            {t("Go")}
                          </Button>
                          {/* <Typography className={styles.green}>{connectionsData?.connection_status}</Typography> */}
                        </Box>
                      ) : // Use parentheses for grouping else if conditions
                        error ? (
                          <Box className={styles.plusIcon}>
                            <Typography className={styles.error}>
                              {error}
                            </Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                      {!connectionsData && isConnectionsLoading ? (
                        <div className={styles.spinner_container}>
                          <div className={styles.loading_spinner}></div>
                        </div>
                      ) : (
                        ""
                      )}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={styles.accordionBox}>
                    <AccordionSummary
                      expandIcon={<AddCircleOutlineIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      onClick={() => checkAuth()}
                    >
                      <Typography> {t("ConnectYourPaypal")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={styles.paypalDetails}>
                      {/* <Typography> */}
                      {AuthError.error === true ? (
                        <Box className={styles.plusIcon}>
                          <Typography className={styles.error}>
                            {AuthError.errorMsg}
                          </Typography>
                        </Box>
                      ) : (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              my: 2,
                            }}
                          >
                            <TextField
                              label="BASIC TOKEN"
                              onChange={handleBasicTokenChange}
                              variant="outlined"
                            />
                          </Box>
                          <Box>
                            <Button
                              onClick={() => {
                                addAuthToken();
                              }}
                              variant="contained"
                            >
                              ADD
                            </Button>
                          </Box>
                        </>
                      )}

                      {/* </Typography> */}
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item md={4} xs={12}>
                  {/* <Box className={styles.search_box}>
                    {showQuery ? (
                      <Box>

                        <FormControl className={styles.form_box} fullWidth>
                          <OutlinedInput
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            placeholder={t("BLZCodePlaceholder")}
                            inputProps={{ maxLength: 8 }}
                            onKeyPress={handleKeyPress}
                            onChange={handleChange}
                          />
                        </FormControl>
                        {customErr.status === true &&
                          <Typography sx={{ color: "red" }}>{customErr?.errorMsg}</Typography>}

                      </Box>
                    ) : ('')}
                  </Box> */}
                </Grid>
                <Grid item md={4} xs={12}></Grid>
              </Grid>
            </Box>

            <Box className={styles.render}>
              <Grid container spacing={2}></Grid>
            </Box>

            <Box>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <TableContainer className={styles.webtable}>
                    <Table
                      className={styles.webT}
                      sx={{ minWidth: 650 }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow className={styles.tablehead}>
                          <TableCell>{t("ConnectionID")}</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Source</TableCell>
                          <TableCell>{t("Action")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!isAllConnectionFetching &&
                          rows &&
                          rows?.length > 0 ? (
                          rows
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            ?.map((connection, key) => (
                              <TableRow key={key}>
                                <TableCell>
                                  {connection?.webform_id}
                                </TableCell>
                                <TableCell>
                                  {connection.connection_status ===
                                    "NOT_YET_OPENED"
                                    ? t("NotYetOpened")
                                    : connection.connection_status === "EXPIRED"
                                      ? t("Expired")
                                      : connection.connection_status ===
                                        "COMPLETED"
                                        ? t("Completed")
                                        : connection.connection_status ===
                                          "COMPLETED_WITH_ERROR"
                                          ? t("CompletedErrorWith")
                                          : connection.connection_status ===
                                            "IN_PROGRESS"
                                            ? t("InProgress")
                                            : connection?.connection_status}
                                </TableCell>
                                <TableCell>Bank</TableCell>
                                <TableCell>
                                  <Box>
                                    <Button
                                      size="medium"
                                      color="error"
                                      disabled={isDeleteLoading}
                                      onClick={() => {
                                        setDeleteId(connection?.webform_id);
                                        deleteConnection(
                                          connection?.webform_id
                                        );
                                      }}
                                    >
                                      {(isDeleteLoading ||
                                        isAllConnectionFetching) &&
                                        deleteId === connection?.webform_id ? (
                                        <>
                                          <CircularProgress
                                            size={18}
                                            color="inherit"
                                          />
                                          {t("Delete")}
                                        </>
                                      ) : (
                                        <>
                                          <DeleteOutlineOutlinedIcon />
                                          {t("Delete")}
                                        </>
                                      )}
                                    </Button>
                                    {connection?.webform_id ===
                                      connectionData?.webform_id &&
                                      loader && (
                                        <CircularProgress
                                          size={18}
                                          color="inherit"
                                        />
                                      )}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))
                        ) : isAllConnectionFetching ? (
                          <CustomSkeleton />
                        ) : (
                          <CustomNoRowsAvailable />
                        )}
                      </TableBody>

                      <TableFooter>
                        <TableRow>
                          {isAllConnectionFetching ? (
                            <TableCell>
                              <Skeleton
                                variant="rect"
                                width="100%"
                                height={50}
                              />
                            </TableCell>
                          ) : (
                            <TablePagination
                              colSpan={4}
                              count={rows?.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={(event, newPage) => {
                                if (newPage > page) {
                                  setPage(page + 1); // Increase page value
                                } else {
                                  setPage(page - 1); // Decrease page value
                                }
                              }}
                            />
                          )}
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default FinApiWebForm;
