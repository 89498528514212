import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, IconButton, InputLabel, ListItemIcon, Select, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import LightModeIcon from '@mui/icons-material/LightMode';
import MuiAppBar from "@mui/material/AppBar";
import styles from "../layout/layout.module.scss";
import ColorModeContext from "_components/theme";
import { useTranslation } from "react-i18next";
import Flag from 'react-world-flags'

//Component Styles
const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const Header = ({ open, handleDrawerOpen }) => {
    //States
    const { t, i18n } = useTranslation();
    const { colorMode, toggleColorMode } = React.useContext(ColorModeContext)
    const user = JSON.parse(localStorage.getItem("user"))
    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
    }
    return (
        <>
            <AppBar className={styles.header_wrp} position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => { handleDrawerOpen() }}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: "none" }),
                        }}
                    >
                        <MenuIcon fontSize="small" sx={{ color: "#9da4ae" }} />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, color: "#9da4ae" }}
                    >
                        {user.groups}
                    </Typography>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, color: "black" }}
                    >
                        {t("logginas")} : {user.email}
                    </Typography>

                    <FormControl className={`${styles.select_language} form_box`}>
                        <InputLabel id="demo-simple-select-label">{t("Language")}</InputLabel>
                        <Select sx={{ maxWidth: "200px" }}
                            value={i18n.language}
                            label="Language"
                            onChange={(event) => handleLanguageChange(event.target.value)}
                        >
                            <MenuItem value={"en"}>
                                <ListItemIcon>
                                    <Flag code={'gb'} />
                                </ListItemIcon>
                            </MenuItem>
                            <MenuItem value={"de"}>
                                <ListItemIcon>
                                    <Flag code={'de'} />
                                </ListItemIcon>
                            </MenuItem>
                            {/* <MenuItem value={"de"}>German</MenuItem> */}
                        </Select>
                    </FormControl>
                    {
                        colorMode === "light" ? <Brightness4Icon className={styles.colorModeIcon} onClick={toggleColorMode} /> : <LightModeIcon className={styles.colorModeIcon} onClick={toggleColorMode} />
                    }
                    {/* <Button variant="" className={styles.logoutBtn} onClick={() => handleLogout()}>Logout</Button> */}
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Header